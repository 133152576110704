import React from 'react';
import { useDialog } from '../../components/dialog/use-dialog';
import ActionButton from '../../components/buttons/ActionButton';
import { useDeletePipelineMutation, useGetPipelinesTenantIdQuery } from '../api/api';
import { useParams } from 'react-router-dom';
import { TableBadges } from '../../components/table/TableBadges';
import { GetDateWith24hTime, ParseCronTimes } from '../../../utility/tables/DateFormatter';


const PipelineList: React.FC = () => {
	const id = Number(useParams<{ id: string }>().id);

	const { data, isFetching } = useGetPipelinesTenantIdQuery({ tenantId: id });

	const [deletePipeline, { isLoading }] = useDeletePipelineMutation();

	const { onOpen: openCreatePipelineDialog } = useDialog('biHubPipelineCreate');
	const { onOpen: openUpdatePipelineDialog } = useDialog('biHubPipelineUpdate');
	const { onOpen: openSchedulePipelineDialog } = useDialog('biHubPipelineSchedule');

	if (!data && isFetching) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		)
	}

	const handleDeletePipeline = async (pipelineId: number) => {
		await deletePipeline({ pipelineId });
	}

	return (
		<div>
			<div className="d-flex justify-content-end align-items-center">
				<ActionButton
					onClick={() => openCreatePipelineDialog({ tenantId: id, tenantName: "" })}
					text='Add New Pipeline'
					showPopConfirm={false}
					color='primary'
					fontIcon='fa fa-plus'
				/>
			</div>

			<div className="mt-4">
				<table className="table table-fixed align-middle gx-5 no-footer table-row-bordered striped border border-gray-200">
					<thead className='bg-gray-200'>
						<tr className="">
							<th scope="col">Name</th>
							<th scope="col">Description</th>
							<th scope="col">Run times</th>
							<th scope="col">Next Execution</th>
							<th scope="col">Last Execution</th>
							<th scope="col">Action</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((pipeline) => (
							<tr key={pipeline.id}>
								<td>{pipeline.name}</td>
								<td>{pipeline.description}</td>
								<td>
									{
										pipeline.cronExpression ?
											<TableBadges
												stringList={ParseCronTimes(pipeline.cronExpression)}
												maxLength={5}
											/>
											: '-'
									}
								</td>
								<td>
									{
										pipeline.nextExecution ? GetDateWith24hTime(new Date(pipeline.nextExecution)) : '-'
									}
								</td>
								<td>
									{
										pipeline.lastExecution ? GetDateWith24hTime(new Date(pipeline.lastExecution)) : '-'
									}
								</td>
								<td className='d-flex justify-content-end' style={{ maxHeight: '60px' }}>
									<ActionButton
										onClick={() => openSchedulePipelineDialog({ id: pipeline.id })}
										text='Schedule'
										showPopConfirm={false}
										color='primary'
									/>

									<ActionButton
										onClick={() => openUpdatePipelineDialog({ tenantName: "", id: pipeline.id })}
										text='Edit'
										showPopConfirm={false}
										color='primary'
									/>

									<ActionButton
										onClick={() => handleDeletePipeline(pipeline.id)}
										text='Delete'
										showPopConfirm={true}
										confirmText='Are you sure you want to delete this pipeline?'
										color='danger'
										disabled={isLoading}
										state={isLoading}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PipelineList;