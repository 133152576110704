import { useCallback, useState, useEffect, useRef } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

interface Props {
  placeholder: string;
}

function InputComponentUrlSearchParam({ placeholder, }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(searchParams.get('keyWord') || '');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === '/') {
        e.preventDefault();
        inputRef.current?.focus();
      } else if (e.key === 'Enter' || e.key === 'Escape') { inputRef.current?.blur(); }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  const debouncedSetSearchParams = useCallback(debounce((newQuery) => {
    const newParams = new URLSearchParams(searchParams);

    if (newQuery) {
      newParams.set('keyWord', newQuery);
    } else {
      newParams.delete('keyWord');
    }

    setSearchParams(newParams, { replace: true, });
  }, 500), [searchParams, setSearchParams]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setInputValue(newQuery);
    debouncedSetSearchParams(newQuery);
  };

  return (
    <>
      <KTSVG
        path="/media/icons/duotune/general/gen021.svg"
        className="svg-icon-1 position-absolute ms-6"
      />
      <input
        ref={inputRef}
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid w-300px ps-14'
        placeholder={`${placeholder} (Ctrl + /)`}
        value={inputValue}
        onChange={handleChange}
      />
    </>
  );
}

export { InputComponentUrlSearchParam };
