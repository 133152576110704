import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
	CancelDrop,
	DndContext,
	DragOverlay,
	DropAnimation,
	MouseSensor,
	TouchSensor,
	Modifiers,
	UniqueIdentifier,
	useSensors,
	useSensor,
	MeasuringStrategy,
	KeyboardCoordinateGetter,
	defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
	SortableContext,
	useSortable,
	arrayMove,
	verticalListSortingStrategy,
	SortingStrategy,
	horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Container } from './Container';
import { Item } from './Item';
import { BiHubPowerBiDatasetDto, BiHubTaskDto } from '../../../api/data-contracts';
import RefreshPowerBiCard from './RefreshPowerBiCard';
import { Steps } from '../api/types';
import DroppableContainer from './DroppableContainer';
import { Message } from '../../../utility/notifications/Message';
import ActionButton from '../../components/buttons/ActionButton';

export default {
	title: 'Presets/Sortable/Multiple Containers',
};

const dropAnimation: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: '0.5',
			},
		},
	}),
};

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface Props {
	//BI Hub Specific
	biHubTasks: Record<UniqueIdentifier, BiHubTaskDto[]>;
	biHubTaskSource: BiHubTaskDto[];
	handleTasksSave: (data: Steps, powerBiDatasets: number[]) => void;
	dashboardRefresh: BiHubPowerBiDatasetDto[];

	adjustScale?: boolean;
	cancelDrop?: CancelDrop;
	columns?: number;
	containerStyle?: React.CSSProperties;
	coordinateGetter?: KeyboardCoordinateGetter;
	getItemStyles?(args: {
		value: UniqueIdentifier;
		index: number;
		overIndex: number;
		isDragging: boolean;
		containerId: UniqueIdentifier;
		isSorting: boolean;
		isDragOverlay: boolean;
	}): React.CSSProperties;
	wrapperStyle?(args: { index: number }): React.CSSProperties;
	itemCount?: number;
	items?: Items;
	handle?: boolean;
	renderItem?: any;
	strategy?: SortingStrategy;
	modifiers?: Modifiers;
	minimal?: boolean;
	trashable?: boolean;
	scrollable?: boolean;
	vertical?: boolean;
	isLoading: boolean;
	tenantId: number;
}

const PLACEHOLDER_ID = 'placeholder';
const SOURCE_ID = 'Source';
const empty: UniqueIdentifier[] = [];

export function MultipleContainers({
	//Bi Hub Specific
	biHubTasks,
	biHubTaskSource,
	handleTasksSave,
	dashboardRefresh,

	adjustScale = false,
	itemCount = 3,
	cancelDrop,
	columns,
	handle = false,
	items: initialItems,
	containerStyle,
	getItemStyles = () => ({}),
	wrapperStyle = () => ({}),
	minimal = false,
	modifiers,
	renderItem,
	strategy = verticalListSortingStrategy,
	vertical = false,
	scrollable,
	isLoading,
	tenantId,
}: Props) {
	const [powerBiValues, setPowerBiValues] = useState<number[]>([]);
	const [tasks, setTasks] = useState<Steps>({});
	const [taskContainers, setTaskContainers] = useState<UniqueIdentifier[]>(
		Object.keys(tasks) as UniqueIdentifier[]
	);
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const [clonedItems, setClonedItems] = useState<Steps | null>(null);

	const recentlyMovedToNewContainer = useRef(false);
	const isSortingContainer = activeId ? taskContainers.includes(activeId) : false;

	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainer.current = false;
		});
	}, [tasks]);

	useEffect(() => {
		setTasks({
			Source: biHubTaskSource,

			...biHubTasks
		});

		setTaskContainers(Object.keys(biHubTasks) as UniqueIdentifier[]);
		setPowerBiValues(dashboardRefresh.map((value) => value.powerBiReportId));
	}, [biHubTasks, biHubTaskSource, dashboardRefresh]);

	const save = () => {
		handleTasksSave(tasks, powerBiValues);
	}

	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
	);
	const findContainer = (id: UniqueIdentifier) => {
		if (id in tasks) {
			return id;
		}

		return Object.keys(tasks).find((key) => tasks[key].some((task) => task.id === id));
	};

	const findTask = (id: UniqueIdentifier) => {
		for (const taskArray of Object.values(tasks)) {
			const foundTask = taskArray.find((task) => task.id === id);
			if (foundTask) {
				return foundTask;
			}
		}
		return null;
	};

	const getIndex = (id: UniqueIdentifier) => {
		const container = findContainer(id);

		if (!container) {
			return -1;
		}

		const index = tasks[container].findIndex((task) => task.id === id);

		return index;
	};

	const onDragCancel = () => {
		if (clonedItems) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setTasks(clonedItems);
		}

		setActiveId(null);
		setClonedItems(null);
	};

	return (
		<DndContext
			sensors={sensors}
			measuring={{
				droppable: {
					strategy: MeasuringStrategy.Always,
				},
			}}
			onDragStart={({ active }) => {
				setActiveId(active.id);
				setClonedItems(tasks);
			}}
			onDragOver={({ active, over }) => {
				const overId = over?.id;

				if (overId == null || active.id in tasks) {
					return;
				}
				const overContainer = findContainer(overId);
				const activeContainer = findContainer(active.id);

				if (!overContainer || !activeContainer) {
					return;
				}

				if (activeContainer !== overContainer) {
					setTasks((tasks) => {
						const activeItems = tasks[activeContainer];
						const overItems = tasks[overContainer];
						const overIndex = overItems.findIndex((task) => task.id === overId);


						const activeIndex = activeItems.findIndex((task) => task.id === active.id);

						let newIndex: number;

						if (overId in tasks) {
							newIndex = overItems.length + 1;
						} else {
							const isBelowOverItem =
								over &&
								active.rect.current.translated &&
								active.rect.current.translated.top >
								over.rect.top + over.rect.height;

							const modifier = isBelowOverItem ? 1 : 0;

							newIndex =
								overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
						}

						recentlyMovedToNewContainer.current = true;

						return {
							...tasks,
							[activeContainer]: tasks[activeContainer].filter(
								(item) => item.id !== active.id
							),
							[overContainer]: [
								...tasks[overContainer].slice(0, newIndex),
								tasks[activeContainer][activeIndex],
								...tasks[overContainer].slice(
									newIndex,
									tasks[overContainer].length
								),
							],
						};
					});
				}
			}}
			onDragEnd={({ active, over }) => {
				if (active.id in tasks && over?.id) {
					setTaskContainers((containers) => {
						const activeIndex = containers.indexOf(active.id);
						const overIndex = containers.indexOf(over.id);

						return arrayMove(containers, activeIndex, overIndex);
					});
				}

				const activeContainer = findContainer(active.id);

				if (!activeContainer) {
					setActiveId(null);
					return;
				}

				const overId = over?.id;

				if (overId == null) {
					setActiveId(null);
					return;
				}

				if (overId === PLACEHOLDER_ID) {
					const newContainerId = getNextContainerId();

					const taskToAdd = tasks[activeContainer].find((task) => task.id === activeId)

					unstable_batchedUpdates(() => {
						setTaskContainers((taskContainers) => [...taskContainers, newContainerId]);

						setTasks((items) => ({
							...items,
							[activeContainer]: items[activeContainer].filter(
								(task) => task.id !== activeId
							),

							[newContainerId]: taskToAdd ? [taskToAdd] : [],
						}));
						setActiveId(null);
					});
					return;
				}

				const overContainer = findContainer(overId);

				if (overContainer) {
					const activeIndex = tasks[activeContainer].findIndex((task) => task.id === active.id);

					const overIndex = tasks[overContainer].findIndex((task) => task.id === overId);

					if (activeIndex !== overIndex) {
						setTasks((items) => ({
							...items,
							[overContainer]: arrayMove(
								items[overContainer],
								activeIndex,
								overIndex
							),
						}));
					}
				}

				setActiveId(null);
			}}
			cancelDrop={cancelDrop}
			onDragCancel={onDragCancel}
			modifiers={modifiers}
		>
			<div className="row h-100" style={{ display: 'flex', flexDirection: 'row' }}>
				<div className="col-6 card d-flex flex-column sticky justify-content-between" style={{ height: '100%' }}>
					<div className='h-75'>
						<div className='h-100'>
							{tasks.Source ?
								<SortableContext items={tasks.Source} strategy={verticalListSortingStrategy}>
									<DroppableContainer
										id={SOURCE_ID}
										columns={1}
										items={tasks.Source.map((task) => task.id)}
										scrollable={scrollable}
										style={{ minWidth: '0' }}
									>
										{tasks.Source.map((value, index) => {
											return (
												<SortableItem
													disabled={isSortingContainer}
													key={value.id}
													id={value.id}
													index={index}
													handle={handle}
													style={getItemStyles}
													wrapperStyle={wrapperStyle}
													renderItem={renderItem}
													containerId={taskContainers[0]}
													getIndex={getIndex}
													task={value}
												/>
											);
										})}
									</DroppableContainer>
								</SortableContext>
								: (
									<h1>No tasks found</h1>
								)}
						</div>
					</div>

					<div className='p-4'>
						<ActionButton onClick={save} text='Save' className='btn btn-primary w-100' color={'primary'} state={isLoading} />
					</div>
				</div>

				<div
					className='col-6 overflow-auto'
					style={{
						display: 'inline-grid',
						boxSizing: 'border-box',
						gridAutoFlow: vertical ? 'row' : 'column',
						maxHeight: '100%'
					}}
				>

					<SortableContext
						items={[...taskContainers, PLACEHOLDER_ID]}
						strategy={
							vertical
								? verticalListSortingStrategy
								: horizontalListSortingStrategy
						}
					>
						{taskContainers.map((containerId, index) => (
							containerId !== SOURCE_ID && (
								<DroppableContainer
									key={containerId}
									id={containerId}
									label={minimal ? undefined : `Step ${index + 1}`}
									columns={columns}
									items={tasks[containerId].map((task) => task.id)}
									scrollable={scrollable}
									style={containerStyle}
									unstyled={minimal}
									onRemove={() => handleRemove(containerId)}
								>
									<SortableContext items={tasks[containerId]} strategy={strategy}>
										{tasks[containerId].map((value, index) => {
											return (
												<SortableItem
													disabled={isSortingContainer}
													key={value.id}
													id={value.id}
													index={index}
													handle={handle}
													style={getItemStyles}
													wrapperStyle={wrapperStyle}
													renderItem={renderItem}
													containerId={containerId}
													getIndex={getIndex}
													task={value}
												/>
											);
										})}
									</SortableContext>
								</DroppableContainer>
							)))}

						{minimal ? undefined : (
							<DroppableContainer
								id={PLACEHOLDER_ID}
								style={containerStyle}
								disabled={isSortingContainer}
								items={empty}
								onClick={handleAddColumn}
								placeholder
							>
								+ Add column
							</DroppableContainer>
						)}

						<RefreshPowerBiCard
							values={powerBiValues}
							setValues={setPowerBiValues}
							tenantId={tenantId}
						/>
					</SortableContext>
				</div>

			</div>
			{
				createPortal(
					<DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
						{activeId
							? taskContainers.includes(activeId)
								? renderContainerDragOverlay(activeId)
								: renderSortableItemDragOverlay(activeId)
							: null}
					</DragOverlay>,
					document.body
				)
			}

		</DndContext >
	);

	function renderSortableItemDragOverlay(id: UniqueIdentifier) {
		const task = findTask(id);

		if (task === null) {
			return null
		}

		return (
			<Item
				value={id}
				handle={handle}
				action={task.action}
				title={task.name}
				style={getItemStyles({
					containerId: findContainer(id) as UniqueIdentifier,
					overIndex: -1,
					index: getIndex(id),
					value: id,
					isSorting: true,
					isDragging: true,
					isDragOverlay: true,
				})}
				color={getColor(task?.type)}
				wrapperStyle={wrapperStyle({ index: 0 })}
				renderItem={renderItem}
				dragOverlay
			/>
		);
	}

	function renderContainerDragOverlay(containerId: UniqueIdentifier) {
		return (
			//@ts-ignore
			<Container
				label={`Step ${containerId}`}
				columns={columns}
				style={{
					height: '100%',
				}}
				shadow
				unstyled={false}
			>
				{tasks[containerId].map((item, index) => (
					<Item
						action={item.action}
						key={item.id}
						value={item.id}
						handle={handle}
						title={item.name}
						style={getItemStyles({
							containerId,
							overIndex: -1,
							index: getIndex(item.id),
							value: item.id,
							isDragging: false,
							isSorting: false,
							isDragOverlay: false,
						})}
						color={getColor(item.type)}
						wrapperStyle={wrapperStyle({ index })}
						renderItem={renderItem}
					/>
				))}
			</Container>
		);
	}

	function handleRemove(containerID: UniqueIdentifier) {
		if (tasks[containerID].length === 0) {
			setTaskContainers((containers) =>
				containers.filter((id) => id !== containerID)
			);
		} else {
			void Message("Cannot remove step with tasks", "Remove tasks first", "");
		}
	}

	function handleAddColumn() {
		const newContainerId = getNextContainerId();

		unstable_batchedUpdates(() => {
			setTaskContainers((containers) => [...containers, newContainerId]);
			setTasks((items) => ({
				...items,
				[newContainerId]: [],
			}));
		});
	}

	function getNextContainerId() {
		const containerIds = Object.keys(tasks);
		const lastContainerId = containerIds[containerIds.length - 1];

		return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
	}
}

function getColor(type: number) {
	switch (type) {
		case 1: // .exe File
			return '#7193f1'; // a soft blue
		case 2: // cmd Script
			return '#ffda6c'; // a warm yellow
		case 3: // Python Script
			return '#00bcd4'; // a bright cyan
		case 4: // SQL Job
			return '#ef769f'; // a gentle pink
		case 5: // SQL Stored Procedure
			return '#f44336'; // a strong red
		case 6: // PowerBI Dataset Refresh
			return '#9c27b0'; // a vivid purple
		case 7: // Kubernetes Container
			return '#4caf50'; // a vibrant green
		case 8: // Data Factory Pipeline
			return '#ff9800'; // a lively orange
		default:
			return undefined;
	}
}
interface SortableItemProps {
	containerId: UniqueIdentifier;
	id: UniqueIdentifier;
	index: number;
	handle: boolean;
	disabled?: boolean;
	style(args: any): React.CSSProperties;
	getIndex(id: UniqueIdentifier): number;
	renderItem(): React.ReactElement;
	wrapperStyle({ index }: { index: number }): React.CSSProperties;
	task: BiHubTaskDto;
}

function SortableItem({
	disabled,
	id,
	index,
	handle,
	renderItem,
	style,
	containerId,
	getIndex,
	wrapperStyle,
	task,
}: SortableItemProps) {
	const {
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		isDragging,
		isSorting,
		over,
		overIndex,
		transform,
		transition,
	} = useSortable({
		id,
	});
	const mounted = useMountStatus();
	const mountedWhileDragging = isDragging && !mounted;

	return (
		<Item
			action={task.action}
			ref={disabled ? undefined : setNodeRef}
			value={id}
			dragging={isDragging}
			sorting={isSorting}
			handle={handle}
			handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
			index={index}
			wrapperStyle={wrapperStyle({ index })}
			title={task.name}
			style={style({
				index,
				value: id,
				isDragging,
				isSorting,
				overIndex: over ? getIndex(over.id) : overIndex,
				containerId,
			})}
			color={getColor(task.type)}
			transition={transition}
			transform={transform}
			fadeIn={mountedWhileDragging}
			listeners={listeners}
			renderItem={renderItem}
		/>
	);
}

function useMountStatus() {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), 500);

		return () => clearTimeout(timeout);
	}, []);

	return isMounted;
}