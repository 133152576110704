
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalMeta } from "../../../types/modal";
import { openModal, closeModal } from "../../../redux/actions";
import { isModalOpen } from "../../../redux/selectors";
import { UIState } from "../../../redux/uireducer";

export function useDialog(modalFileName: string) {
    const dispatch = useDispatch();

    /* eslint-disable react-hooks/exhaustive-deps */
    const onOpen = useCallback(
        (meta: ModalMeta) => dispatch(openModal(modalFileName, meta)),
        [modalFileName]
    );
    const onClose = useCallback(() => dispatch(closeModal(modalFileName)), [
        modalFileName
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const isOpen = useSelector<UIState>((state) => {
        return isModalOpen(state, modalFileName);
    }
    );

    return {
        isOpen,
        onOpen,
        onClose
    };
}
