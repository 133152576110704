import AppFormLabel from './AppFormLabel';
import { useFormikContext } from 'formik';
import AppFormError from './AppFormError';
import { objectByString } from '../../../utility/Utils';
import { TimePicker, type TimePickerProps } from 'antd';
import { type Dayjs } from 'dayjs';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  timePickerProps?: TimePickerProps;
}

function AppFormTimePickerAnt({
  name,
  label,
  required = false,
  disabled = false,
  timePickerProps,
}: Props) {
  const { setFieldValue, errors, values, } = useFormikContext();

  const onChange = (time: Dayjs | null) => {
    setFieldValue(name, time);
  };

  return (
    <div className="d-flex flex-column">
      <AppFormLabel label={label} required={required} />
      <TimePicker
        disabled={disabled}
        value={objectByString(values, name)}
        name='specificTime'
        showNow={true}
        onChange={onChange}
        {...timePickerProps}
      />

      <AppFormError
        visible={objectByString(errors, name)}
        error={objectByString(errors, name)}
      ></AppFormError>
    </div >
  );
}

export default AppFormTimePickerAnt;
