import React, { useEffect, useState } from 'react';
import 'rc-tree/assets/index.css';
import { Formik } from 'formik';
import AppFormButton from '../../components/forms/AppFormButton';
import { useLazyGetPipelineForRunPipelineQuery, useRunPipelineMutation } from '../api/api';
import CheckableTable from '../components/CheckableTable';
import { Message, RtkErrorMessage } from '../../../utility/notifications/Message';
import { extractIds } from '../../../utility/Utils';
import { type PowerBIReportDropdownDto } from '../../content-manager/PowerBi/api/types';
import AppNotification from '../../../utility/notifications/AppNotifications';
import { useSelectRows } from '../hooks/SelectRowsHook';

export interface Props {
  tenantId: number;
};

function RunPipelineForm({ tenantId, }: Props) {
  const [getPipeline, { data: pipelineData, isFetching: pipelineLoading, }] = useLazyGetPipelineForRunPipelineQuery();
  const [runPipeline, { isLoading: pipelinePosingLoading, }] = useRunPipelineMutation();
  const [selectedOptions, setSelectedOptions] = useState<PowerBIReportDropdownDto[]>([]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [tenantId]);

  useEffect(() => {
    getPipeline({ pipelineId: tenantId, })
      .unwrap()
      .catch((e) => { void RtkErrorMessage("Getting pipeline failed", e); });
  }, [tenantId]);

  console.log(pipelineData)


  const { selectedRows, handleSelectAll, handleSelectRow, deselectAll, } =
    useSelectRows();

  const handleSubmit = async () => {
    const powerBIReportsTorefresh = selectedRows.filter(row => row.type === 6);
    const tasksToRefresh = selectedRows.filter(row => row.type !== 6);

    await runPipeline({
      payload: {
        pipelineId: tenantId,
        taskIds: extractIds(tasksToRefresh),
        powerBiDatasetIds: extractIds(powerBIReportsTorefresh),
      },
    })
      .unwrap()
      .then(() => {
        AppNotification.info('BI hub', 'Pipeline is running!');
      })
      .catch(async (error) => {
        await Message('Running pipeline failed!', error.data.exception, 'error', {});
      });
  };

  useEffect(() => {
    if (pipelineData?.tasks) {
      handleSelectAll(pipelineData.tasks);
    }
  }, [pipelineData]);

  return (
    <>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, }) => (
          <form className='form w-100 h-100' onSubmit={(event) => { event.preventDefault(); }} noValidate
            id='role-form'>
            <div className="column">
              <div className="col">
                <div className="w-100 h-100 mb-5">
                  {!pipelineLoading
                    ? (
                      <>
                        {pipelineData && pipelineData.tasks && pipelineData.tasks.length > 0
                          ? (
                            <div className="d-flex flex-column gap-4">
                              <CheckableTable
                                rows={pipelineData.tasks!}
                                handleSelectRow={handleSelectRow}
                                deselectAll={deselectAll}
                                handleSelectAll={() => { handleSelectAll(pipelineData.tasks ?? []); }}
                                selectedRows={selectedRows} />

                              <AppFormButton
                                width='w-20'
                                type='submit'
                                text={'Refresh data'}
                                state={pipelinePosingLoading}
                                color={'primary'}
                                disabled={isSubmitting || !isValid || selectedRows.length === 0 && selectedOptions.length === 0}
                                onClick={handleSubmit}
                              />

                            </div>
                          )
                          : <p>No tasks found</p>
                        }
                      </>
                    )
                    : (
                      <span className='indicator-progress' style={{ display: 'block', }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )
                  }
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { RunPipelineForm };
