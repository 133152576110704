import { useCallback, useState } from 'react';
import { type AgGridReact } from 'ag-grid-react';
import { type CellPosition, type ProcessDataFromClipboardParams } from 'ag-grid-enterprise';
import { deleteRowsRangeSelection } from '../../../utility/AgUtils';
import { randStringPrefix } from '../../../utility/Utils';

export const NEWLY_ADDED_PREFIX = 'newly_added_';

export const useTransactionsDimMapping = (tableId: string) => {
  const [transactions, setTransactions] = useState<any>({});

  const insertRow = useCallback(async (index: number, gridRef: React.RefObject<AgGridReact<any>>) => {
    const selectedRanges = gridRef.current!.api.getCellRanges();

    const newRow: any = {};
    gridRef.current!.columnApi.getAllDisplayedColumns().forEach((column) => {
      newRow[column.getColId()] = null;
    });
    newRow[tableId] = randStringPrefix(NEWLY_ADDED_PREFIX);

    // If no rows are selected we add one at the bottom
    if (!selectedRanges?.[0]) {
      gridRef.current!.api.applyTransaction({ add: [newRow], addIndex: 0, });

      setTransactions((prevTransactions: any) => ({
        ...prevTransactions,
        add: [...(prevTransactions.add ?? []), newRow],
      }));

      return;
    }

    const columnId = selectedRanges[0].startColumn.getColId();
    const startIndex = selectedRanges[0].startRow!.rowIndex;
    const row = gridRef.current!.api.getDisplayedRowAtIndex(startIndex);

    if (!row) return;

    // Here we reset the sorting
    gridRef.current!.columnApi.applyColumnState({
      defaultState: { sort: null, },
    });

    gridRef.current!.api.clearRangeSelection();

    // Dirty trick for waiting for the new sorting to be finished
    await new Promise((r) => setTimeout(r, 500));

    if (row.id) {
      const rowNode = gridRef.current!.api.getRowNode(row.id);

      if (!rowNode || rowNode.rowIndex === null) {
        return;
      }

      const addIndex = rowNode.rowIndex + 1;

      if (!addIndex) return;

      gridRef.current!.api.applyTransaction({ add: [newRow], addIndex, });
      gridRef.current!.api.ensureIndexVisible(addIndex, 'middle');

      // Dirty trick for waiting for the row to be added before we can focus it
      await new Promise((r) => setTimeout(r, 250));

      gridRef.current!.api.setFocusedCell(addIndex, columnId, null);

      gridRef.current!.api.startEditingCell({
        rowIndex: addIndex,
        colKey: columnId,
      });

      gridRef.current!.api.addCellRange({
        rowStartIndex: addIndex,
        rowEndIndex: addIndex,
        columnStart: columnId,
        columnEnd: columnId,
      });
    } else {
      console.error('Row not found');
    }

    setTransactions((prevTransactions: any) => ({
      ...prevTransactions,
      add: [...(prevTransactions.add ?? []), newRow],
    }));
  }, [setTransactions, tableId]);

  const updateRow = useCallback((row: any) => {
    setTransactions((prevTransactions: any) => {
      // If there are added rows, check if the row is in them
      if (prevTransactions.add) {
        const foundAddIndex = prevTransactions.add.findIndex((r: any) => r[tableId] === row[tableId]);
        if (foundAddIndex !== -1) {
          const updatedAdds = [...prevTransactions.add];
          updatedAdds[foundAddIndex] = row;
          return {
            ...prevTransactions,
            add: updatedAdds,
          };
        }
      }
      // Check if the row is already in the updated rows and replace it if found
      const updatedIndex = prevTransactions.update?.findIndex((r: any) => r[tableId] === row[tableId]);

      if (updatedIndex !== undefined && updatedIndex !== -1) {
        const updatedUpdates = [...(prevTransactions.update ?? [])];
        updatedUpdates[updatedIndex] = row;
        return {
          ...prevTransactions,
          update: updatedUpdates,
        };
      }

      // If not found in updated rows, simply append
      return {
        ...prevTransactions,
        update: [...(prevTransactions.update ?? []), row],
      };
    });
  }, [setTransactions, tableId]);

  // Rest of your component
  const deleteRow = useCallback((gridRef: React.RefObject<AgGridReact<any>>) => {
    const rows = deleteRowsRangeSelection(gridRef.current!.api);

    setTransactions((prevTransactions: any) => {
      const updatedAdds = prevTransactions.add?.filter((addedRow: any) => !rows.some((row: any) => row[tableId] === addedRow[tableId])) ?? [];
      const updatedRemoves = rows.filter((row: any) => !prevTransactions.add?.some((addedRow: any) => addedRow[tableId] === row[tableId]));
      const updatedUpdates = prevTransactions.update?.filter((updatedRow: any) => !rows.some((row: any) => row[tableId] === updatedRow[tableId])) ?? [];

      return {
        ...prevTransactions,
        add: updatedAdds,
        update: updatedUpdates,
        remove: [...(prevTransactions.remove ?? []), ...updatedRemoves],
      };
    });
  }, [setTransactions, tableId]);

  const getContextMenuItems = useCallback((params: any, gridRef: React.RefObject<AgGridReact<any>>) => [
    'cut',
    'copy',
    'paste',
    'separator',
    {
      name: 'Insert row above',
      action: async () => { await insertRow(params.node ? params.node.rowIndex : 0, gridRef); },
    },
    {
      name: 'Insert row below',
      action: () => {
        insertRow(params.node ? params.node.rowIndex + 1 : 0, gridRef);
      },
    },
    {
      name: 'Delete row',
      action: () => {
        deleteRow(gridRef);
      },
    }
  ], [insertRow, deleteRow]);

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data: string[][] = [...params.data];

      // Convert numbers in parentheses to their negative counterparts
      data.forEach(row => {
        row.forEach((item, index) => {
          let newValue = item.replace(/,/g, '.');

          const match = newValue.match(/^\((\d+)\)$/);
          if (match) {
            newValue = `-${match[1]}`;
          }

          row[index] = newValue;
        });
      });

      if (data.length > 0 && data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
        data.pop();
      }

      const model: any = params.api.getModel();
      const focusedCell: CellPosition | null = params.api.getFocusedCell();
      if (!focusedCell) return null;

      const lastIndex: number = model.getRowCount() - 1;
      const focusedIndex: number = focusedCell.rowIndex;
      const endRowIndex: number = focusedIndex + data.length - 1;

      if (endRowIndex > lastIndex) {
        const numRowsToAdd: number = endRowIndex - lastIndex;
        const rowsToAdd: any[] = [];

        for (let i = 0; i < numRowsToAdd; i++) {
          const row: string[] = data[data.length - 1 - i];
          const rowObject: Record<string, any> = {};

          let currentColumn: any = focusedCell.column;
          row.forEach((item: string) => {
            if (currentColumn) {
              rowObject[currentColumn.colDef.field] = item;
              currentColumn = params.columnApi.getDisplayedColAfter(currentColumn);
            }
          });

          rowObject[tableId] = randStringPrefix(NEWLY_ADDED_PREFIX);

          params.columnApi.getAllDisplayedColumns().forEach((column) => {
            const colId: string = column.getColId();
            if (rowObject[colId] === undefined) {
              rowObject[colId] = null;
            }
          });

          rowsToAdd.push(rowObject);
        }

        params.api.applyTransaction({ add: rowsToAdd, });
        setTransactions((prevTransactions: any) => ({
          ...prevTransactions,
          add: [...(prevTransactions.add ?? []), ...rowsToAdd],
        }));
      }

      return data;
    },
    []
  );


  const undoDelete = useCallback((gridRef: React.RefObject<AgGridReact<any>>) => {
    setTransactions((prevTransactions: any) => {
      if (prevTransactions.remove && prevTransactions.remove.length > 0) {
        gridRef.current!.api.applyTransaction({ add: prevTransactions.remove, });

        prevTransactions.remove = [];

        const latestItem = gridRef.current!.api.getDisplayedRowCount();
        gridRef.current!.api.ensureIndexVisible(latestItem - 1, 'bottom');
        return {
          ...prevTransactions,
          remove: [...prevTransactions.remove],
        };
      }
      return prevTransactions;
    });
  }, [setTransactions]);

  const clearTransactions = () => {
    setTransactions({});
  };

  const clearRemoved = () => {
    setTransactions((prevTransactions: any) => ({
      ...prevTransactions,
      remove: [],
    }));
  };

  return {
    transactions, updateRow, deleteRow, insertRow, clearTransactions, clearRemoved, processDataFromClipboard, getContextMenuItems, undoDelete,
  };
};
