import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useGetPowerBiDropdownQuery } from '../api/api';

type Props = {
    values: number[];
    setValues: React.Dispatch<React.SetStateAction<number[]>>;
    tenantId: number;
}

const RefreshPowerBiCard = ({ values, setValues, tenantId }: Props) => {
    const { data } = useGetPowerBiDropdownQuery({ tenantId: tenantId });

    return (
        <div className='mb-4 bg-gray-200 h-200px p-2 m-3 shadow  '>
            <h1 className=''>Refresh powerBI</h1>
            <p className='text-muted'>These will always run at the end</p>
            <Select
                mode="multiple"
                className='w-100'
                placeholder="Please select"
                options={data}
                onChange={setValues}
                value={values}
            />
        </div>
    );
};

export default RefreshPowerBiCard;