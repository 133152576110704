import { useParams } from 'react-router-dom';
import { useListView } from '../../utility/ListView';
import { useGetPipelineQuery, useGetTaskListQuery, useUpdatePipelineTasksMutation } from './api/api';
import { MultipleContainers } from './components/MultipleContainers';
import { BiHubTaskDto, UpdateBiHubTaskPipeline } from '../../api/data-contracts';
import { useEffect, useState } from 'react';
import { Steps } from './api/types';
import { RtkErrorMessage } from '../../utility/notifications/Message';
import AppNotification from '../../utility/notifications/AppNotifications';

type Props = {
	tenantId: number;
};

export const PipelineMoveTasks = ({ tenantId }: Props) => {
	let { id } = useParams();

	const { data: pipeline } = useGetPipelineQuery(Number(id));
	const { data: tasks } = useGetTaskListQuery({ tenantId: Number(tenantId) });
	const [updatePipelineTasks, { isLoading }] = useUpdatePipelineTasksMutation();

	const [transFormedTasks, setTransformedTasks] = useState<Record<number, BiHubTaskDto[]>>({});
	const [sourceTasks, setSourceTasks] = useState<BiHubTaskDto[]>([]);

	const handleTasksSave = (data: Steps, dataSets: number[]) => {
		let taskList: UpdateBiHubTaskPipeline[] = [];

		let tasksToSave: Steps = JSON.parse(JSON.stringify(data));
		delete tasksToSave.Source;

		Object.entries(tasksToSave).map(([_, value], index) => value.map((element) => {
			taskList.push({ id: element.id, step: index });
		}));

		let mappedDatasets = dataSets.map(n => {
			let correspondingDataset;

			if (pipeline?.powerBiDatasets)
				correspondingDataset = pipeline?.powerBiDatasets.find((element) => element.powerBiReportId === n)

			return {
				id: correspondingDataset?.id,
				powerBiReportId: n
			}
		});

		updatePipelineTasks({ pipelineId: Number(id), tasks: taskList, datasets: mappedDatasets })
			.unwrap()
			.then(() => {
				AppNotification.success("Tasks saved successfully");
			})
			.catch((e) => {
				void RtkErrorMessage("Saving Tasks failed", e)
			});
	};

	useEffect(() => {
		if (pipeline && pipeline.tasks) {
			let reducedList = pipeline.tasks.reduce<{ [key: number]: BiHubTaskDto[] }>((acc, task) => {
				acc[task.step] = [...(acc[task.step] || []), task];
				return acc;
			}, {});

			setTransformedTasks(reducedList);
			setSourceTasks(tasks?.filter(task => !pipeline.tasks!.some(pipelineTask => pipelineTask.id === task.id)) || []);
		}
		else
			setSourceTasks(tasks || [])
	}, [pipeline, tasks]);

	return (
		<MultipleContainers
			biHubTasks={transFormedTasks}
			biHubTaskSource={sourceTasks}
			dashboardRefresh={pipeline?.powerBiDatasets ?? []}
			itemCount={2}
			scrollable
			columns={1}
			vertical={true}
			handleTasksSave={handleTasksSave}
			isLoading={isLoading}
			tenantId={Number(tenantId)}
		/>
	)
};
