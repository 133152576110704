import { UniqueIdentifier } from "@dnd-kit/core";
import { BiHubPipelineDto, BiHubTaskDto } from "../../../api/data-contracts";

export type Steps = Record<UniqueIdentifier, BiHubTaskDto[]>

export interface BiHubPipeline extends BiHubPipelineDto {
  specificMinutes: string;
  specificHours: string[];
  recurringDays: number[];
}

export const DataTypesMap = new Map<number, string>([
  [1, '.exe File'],
  [2, 'cmd Script'],
  [3, 'Python Script'],
  [4, 'SQL Job'],
  [5, 'SQL Stored Procedure'],
  [6, 'PowerBI Dataset Refresh'],
  [7, 'Kubernetes Container'],
  [8, 'Data Factory Pipeline']
]);

export const DataTypesList = [
  { label: '.exe File', value: 1 },
  { label: 'cmd Script', value: 2 },
  { label: 'Python Script', value: 3 },
  { label: 'SQL Job', value: 4 },
  { label: 'SQL Stored Procedure', value: 5 },
  { label: 'Kubernetes Container', value: 7 },
  { label: 'Data Factory Pipeline', value: 8 },
];

export const StatusTypesMap = new Map<number, string>([
  [-3, 'HubCommunicationFailed'],
  [-2, 'Timeout'],
  [-1, 'Error'],
  [0, 'NotStarted'],
  [1, 'Started'],
  [5, 'FinishedWithError'],
  [10, 'Finished']
]);

export const actionReplacement = 'Executes';

export type TimeOption = 'SpecificTime' | 'RangeTime';
