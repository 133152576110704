import { Formik } from "formik";
import AppFormButton from "../components/forms/AppFormButton";
import AppTextInput from "../components/forms/AppTextInput";
import AppFormTimePickerAnt from "../components/forms/AppFormTimePickerAnt";
import AppCheckInput from "../components/forms/AppCheckInput";
import { useGetTenantBiHubSettingsQuery, useUpdateTenantBiHubSettingsMutation, } from "./api/api";
import { Spinner } from "react-bootstrap";
import { convertToSeconds, secondsToDayjs } from "../../utility/Utils";
import { RtkErrorMessage } from "../../utility/notifications/Message";
import * as Yup from 'yup';
import { useParams } from "react-router-dom";
import AppFormDropdownAnt from "../components/forms/AppFormDropdownAnt";

const timeFormat = 'HH:mm';

const formSchema = Yup.object().shape({

});

const BiHubCompanySettingPage = () => {
    const id = Number(useParams<{ id: string }>().id);

    const { data, isFetching } = useGetTenantBiHubSettingsQuery({ tenantId: id });

    const [updateBiHubSettings, { isLoading }] = useUpdateTenantBiHubSettingsMutation();

    if (isFetching) {
        return (
            <div className="d-flex justify-content-center h-100">
                <Spinner animation='border' role='status'>
                </Spinner>
            </div>
        );
    }

    const handleSubmit = async (values: any) => {
        values = {
            ...values,
            tenantId: id,
            pipelineTenantRunIntervalInSeconds: convertToSeconds(values.pipelineTenantRunIntervalInSeconds),
        }

        await updateBiHubSettings(values)
            .unwrap()
            .catch((error: any) => {
                RtkErrorMessage("Updating pipeline failed", error);
            });
    }


    const initialValues = {
        ...data,
        isEnabledForTenant: data?.isEnabledForTenant ?? false,
        pipelineTenantRunIntervalInSeconds: secondsToDayjs(data?.pipelineTenantRunIntervalInSeconds ?? 0),
    }

    return <div>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, values, isSubmitting, isValid, }) => (
                <form className='form w-100 h-100' onSubmit={handleSubmit} noValidate>
                    <h2>Company Settings</h2>

                    <div className="col col-md-12">
                        <AppCheckInput
                            name='isEnabledForTenant'
                            label='Enable BI Hub for company'
                        />
                    </div>

                    <div className="col col-md-12">
                        <AppFormDropdownAnt
                            name='pipelineId'
                            label='Select Pipeline'
                            options={data?.availablePipelines ?? []}
                            loading={false}
                            disabled={!values['isEnabledForTenant']}
                        />
                    </div>

                    <div className=" col col-md-12 mb-4">
                        <AppFormTimePickerAnt
                            label='Minimal elapsed '
                            name={'pipelineTenantRunIntervalInSeconds'}
                            required={false}
                            timePickerProps={{ showNow: false, format: timeFormat }}
                            disabled={!values['isEnabledForTenant']}
                        />
                    </div>

                    <div className="mb-4">
                        <AppTextInput
                            label="Dataset explanation text (Viewable for company)"
                            required={false}
                            name="lastUpdatedExplanationText"
                            disabled={!values['isEnabledForTenant']}
                        />
                    </div>

                    <div className="separator my-5"></div>

                    <div className="mt-5">
                        <AppFormButton
                            width={'w-20'}
                            type='submit'
                            text='Submit'
                            state={isLoading}
                            color='primary'
                            disabled={isSubmitting || !isValid}
                            onClick={() => handleSubmit}
                        />
                    </div>
                </form>
            )}
        </Formik>
    </div>;
};

export default BiHubCompanySettingPage;