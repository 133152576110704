/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import {
  Common_Roles_View,
  Common_Users_View, Host_BiHub_View,
  Host_DocumentCabinet_View,
  Host_Edition_View,
  Host_ImportFormSqlLogs_View,
  Host_Tenants_View
} from '../../../../app/constants/permissions';
import routeNames, { AppRoutes } from '../../../../app/routing/routeNames';

export function AsideMenuHost() {
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pb-2">
          <span className="text-white text-uppercase fs-8 ls-1">Host</span>
        </div>
      </div>

      <AsideMenuItem
        to={routeNames.tenant}
        icon="/media/icons/duotune/art/art002.svg"
        title="Companies"
        fontIcon="las la-briefcase"
        permission={Host_Tenants_View}
      />

      <AsideMenuItemWithSub
        to=""
        title="Administration"
        icon="/media/icons/duotune/communication/com006.svg"
        fontIcon="las la-sliders-h"
        permissions={[Common_Roles_View, Common_Users_View]}
      >
        <AsideMenuItem
          to={routeNames.role}
          title="Roles"
          fontIcon="las la-user-tag"
          permission={Common_Roles_View}
        />
        <AsideMenuItem
          to={routeNames.user}
          title="Users"
          fontIcon="las la-users-cog"
          permission={Common_Users_View}
        />
        <AsideMenuItem
          to={AppRoutes.editionPage.path}
          title={AppRoutes.editionPage.userFriendlyName}
          fontIcon='fa-solid fa-tags'
          permission={Host_Edition_View}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to=""
        title="Logs"
        fontIcon="las fa-archive"
        permissions={[Host_ImportFormSqlLogs_View]}
      >
        <AsideMenuItem
          to={routeNames.importFormLogs}
          title="Import form sql"
          permission={Host_ImportFormSqlLogs_View}
          fontIcon=""
        />
        <AsideMenuItem
          to={routeNames.documentCabinetLogs}
          title="Document cabinet log"
          permission={Host_DocumentCabinet_View}
          fontIcon=""
        />
        <AsideMenuItem
          to={routeNames.transactionsHistoryLogs}
          title="Account mapping history"
          permission={Host_DocumentCabinet_View}
          fontIcon=""
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to=''
        title='BI Hub'
        fontIcon='las la-project-diagram'
        permissions={[Host_BiHub_View]}
      >
        <AsideMenuItem
          to={AppRoutes.biHubPage.path}
          title={AppRoutes.biHubPage.userFriendlyName}
          fontIcon='fa-solid fa-bars-progress'
          permission={Host_BiHub_View}
        />

        <AsideMenuItem
          to={AppRoutes.allPipelineRuns.path}
          title={AppRoutes.allPipelineRuns.userFriendlyName}
          fontIcon='fa-regular fa-clock'
          permission={Host_BiHub_View}
        />
      </AsideMenuItemWithSub>

    </>
  );
}
