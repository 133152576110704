const routeNames = {
  login: '/auth/login',
  fallback: '/fallback',
  oldLogin: '/account/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/ResetPassword/',
  activatePassword: '/auth/activate-account/',
  user: '/user',
  masterUser: '/master-user',
  faq: '/faq',
  role: '/role',
  tenant: '/company',
  biHub: '/Bi-Hub/pipelines',
  biHubSchedular: '/Bi-Hub/schedular',
  settings: '/settings',
  powerBi: '/dashboards',
  ssrs: '/management-reports',
  documentCabinet: '/document-cabinet',
  importForm: '/importform',
  importFormLogs: '/logs/ImportFormLogs',
  documentCabinetLogs: '/logs/DocumentsCabinetLogs',
  transactionsHistoryLogs: '/logs/TransactionsHistory',
  mapping: '/mapping',
  notAuthorized: '/error',
  wildcard: '/*',
  contentPage: '/content/overview',
  contentPaginatedReport: '/content/paginatedReport',
  tenantMapping: '/mapping',
  statistics: '/statistics',
  forecasting: '/forecasting',
};

export default routeNames;

export const routeNamesPrefix = {
  ssrs_paginated: `${routeNames.ssrs}/paginatedReport`,
};

export const AppRoutes = {
  allPipelineRuns: {
    path: '/allPipelineRuns',
    userFriendlyName: 'Pipeline runs',
  },

  biHubPage: {
    path: '/Bi-Hub/',
    userFriendlyName: 'BI Hub',
  },

  companyPage: {
    path: '/Bi-Hub/tenant',
    userFriendlyName: 'Pipeline runs',
  },


  companySettingsPage: {
    path: '/Bi-Hub/tenant',
    userFriendlyName: 'Pipeline runs',
  },

  pipelinePage: {
    path: '/Bi-Hub/pipeline',
    userFriendlyName: 'Pipeline runs',
  },

  pipelineEditPage: {
    path: '/Bi-Hub/tenant/:tenantId/pipeline/edit',
    userFriendlyName: 'Pipeline edit'
  },
  editionPage: {
    path: '/edition',
    userFriendlyName: 'Editions',
  },
};
