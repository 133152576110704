import {
  createContext, useContext, useState
} from 'react';
import {
  type PaginationState, type QueryRequestContextProps, type SearchState, type SortState
} from '../types';
import useModal from './modals/useModal';

export const initialQueryState: QueryState = {
  skipCount: 0,
  maxResultCount: 10,
  page: 1,
  totalItemCount: 0,
  pageCount: 0,
};

export type QueryState = PaginationState & SortState & SearchState;

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
};

interface IListViewContext {
  isShowing: boolean;
  toggle: (id: number | null) => void;
  id: number | null;
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
}

const ListViewContext = createContext<IListViewContext>({
  isShowing: false,
  toggle(id: number | null): void {
  },
  id: null,
  state: initialQueryRequest.state,
  updateState(): void { },
});

interface ListViewProviderType {
  children: React.ReactNode;
};

function ListViewProvider({ children, }: ListViewProviderType) {
  const { toggle, isShowing, id, } = useModal();
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates, } as QueryState;
    setState(updatedState);
  };

  return (
    <ListViewContext.Provider value={{
      toggle, isShowing, id, state, updateState,
    }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };
