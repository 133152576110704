/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { itemsPerPage, type ItemsPerPage } from '../../../types';

interface Props {
  totalItemCount: number;
  onPageChange: (pageNumber: number) => void;
  onMaxPageSizeChange: (pageSize: ItemsPerPage) => void;
}

function PaginationSearchParam({ totalItemCount, onPageChange, onMaxPageSizeChange, }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') ?? '1', 10);
  const rowsPerPage = parseInt(searchParams.get('maxResultCount') ?? '10', 10);
  const [pageCount, setPageCount] = useState(0);
  const [paginationRange, setPaginationRange] = useState([2, 3, 4]);

  useEffect(() => {
    setPageCount(Math.ceil(totalItemCount / rowsPerPage));
  }, [totalItemCount, rowsPerPage]);

  useEffect(() => {
    updatePaginationRange();
  }, [currentPage, pageCount]);

  const updatePaginationRange = () => {
    const paginationRange = [];
    const paginationStart = currentPage - 2 < 1 ? 1 : currentPage - 2;
    const paginationEnd = currentPage + 3 > pageCount ? pageCount : currentPage + 3;

    for (let i = paginationStart; i <= paginationEnd; i++) {
      paginationRange.push(i);
    }
    setPaginationRange(paginationRange);
  };

  const updatePage = (page: number | null) => {
    if (!page || currentPage === page) {
      return;
    }
    setSearchParams({ page: page.toString(), rows: rowsPerPage.toString(), });
    onPageChange(page);
  };

  const updateItemsPerPage = (maxCount: ItemsPerPage | null) => {
    if (!maxCount) {
      return;
    }
    setSearchParams({ page: '1', rows: maxCount.toString(), });
    onMaxPageSizeChange(maxCount);
  };

  const paginationList = [] as any;

  paginationRange.forEach((i) => {
    paginationList.push(
      <li
        key={i}
        className={clsx('page-item', {
          active: currentPage === i,
        })}
      >
        <a
          className={clsx('page-link', {})}
          onClick={() => { updatePage(i); }}
          style={{ cursor: 'pointer', }}
        >
          {i}
        </a>
      </li>
    );
  });

  return (
    <div className="d-flex row mt-5">
      <div className="container">
        <div className="row justify-content-start align-content-center">
          <div className="col col-12 col-md-3 col-lg-2 mb-4">
            <div className="d-flex flex-row">
              <label>
                Total {totalItemCount}
                {' '}
                results
                {' '}
              </label>
              <select
                className="form-select"
                value={rowsPerPage}
                onChange={(e) => {
                  const selectedItemsPerPage = e.target.value as unknown as ItemsPerPage;
                  updateItemsPerPage(selectedItemsPerPage);
                }}
              >
                {itemsPerPage.map((market: ItemsPerPage, i) => <option key={i} value={market}>{market}</option>)}
              </select>
            </div>
          </div>
          <div className="col col-12 col-md-7">
            {pageCount > 1 && (
              <div className="d-flex justify-content-center align-content-center">
                <ul className="pagination">
                  <li
                    className={clsx('page-item', {
                      disabled: currentPage === 1,
                    })}
                  >
                    <a
                      className="page-link"
                      role="button"
                      onClick={() => { updatePage(1); }}
                    >
                      <span className="fa fa-angle-double-left" aria-hidden="true" />
                    </a>
                  </li>
                  <li
                    className={clsx('page-item', {
                      disabled: currentPage === 1,
                    })}
                  >
                    <a
                      className="page-link"
                      role="button"
                      onClick={() => { updatePage(currentPage - 1); }}
                    >
                      <span className="fa fa-angle-left" aria-hidden="true" />
                    </a>
                  </li>
                  {paginationList}
                  <li
                    className={clsx('page-item Next', {
                      disabled: currentPage === pageCount,
                    })}
                  >
                    <a
                      className={clsx('page-link page-text', {
                      })}
                      onClick={() => { updatePage(currentPage + 1); }}
                      style={{ cursor: 'pointer', }}
                    >
                      <span className="fa fa-angle-right" aria-hidden="true" />
                    </a>
                  </li>
                  <li
                    className={clsx('page-item Next', {
                      disabled: currentPage === pageCount,
                    })}
                  >
                    <a
                      className="page-link page-text"
                      onClick={() => { updatePage(pageCount); }}
                      role="button"
                    >
                      <span className="fa fa-angle-double-right" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start position-relative w-100">
        <div className="align-items-center ml-auto" />
        <div className="flex-sm-column position-absolute" style={{ height: '100px', left: '50%', transform: 'translateX(-50%)', }} />
      </div>
    </div>
  );
}

export { PaginationSearchParam };
