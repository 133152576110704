import { type CreateEditTenantDto, type Tenant } from './Types';
import { emptySplitApi, invalidationTags } from '../../../utility/emptySplitApi';
import { PowerBiApi_Host_Route, Tenant_Common_Route, Tenant_Host_Route } from '../../../constants/routes';
import { type GetRequestFilter } from '../../../types';
import { Api } from '../../../api/ApiRoute';
import { makeListSelectReady, replacePathParams, setUrlParams } from '../../../utility/Utils';
import { OptionValueLabelDto, TenantDtoResult, TenantOptionsDto, type CreateTenantRequestDto, type DimMappingChangeLogDtoPagedResultWithFiltersDtoResult, type PatchTenantStatusDto, type UpdateTenantRequestDto } from '../../../api/data-contracts';

type NewType = OptionValueLabelDto;

const tenantApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getTenants: build.query({
      query: (input: Api.V1HostTenantsPagedList.RequestQuery) => ({
        url: Api.V1HostTenantsPagedList.BaseRequest.url,
        method: Api.V1HostTenantsPagedList.BaseRequest.method,
        params: input,
      }),
      transformResponse: (e: DimMappingChangeLogDtoPagedResultWithFiltersDtoResult) => e.data,
      providesTags: [invalidationTags.TENANT],
    }),

    getTenantByIdNew: build.query({
      query: (input: Api.V1HostTenantsDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostTenantsDetail.BaseRequest.url, input),
        method: Api.V1HostTenantsDetail.BaseRequest.method,
      }),
      transformResponse: (e: TenantDtoResult) => e.data,
      providesTags: [invalidationTags.TENANT, invalidationTags.EDITION],
    }),

    // Should be removed
    getTenantById: build.query({
      query: (id) => Tenant_Host_Route + id,
      transformResponse: (rawResult: { data: CreateEditTenantDto }) => rawResult.data,
      providesTags: ['MigrateReport', 'Tenant', invalidationTags.EDITION],
    }),

    getTenantLogoByAccessToken: build.query({
      query: () => ({
        url: `${Tenant_Common_Route}logo`,
        responseHandler: async (response) => await response.text(),
      }),
      providesTags: ['TenantLogo'],
    }),

    getTenantLogo: build.query({
      query: (id) => ({
        url: `${Tenant_Common_Route}logo?tenantId=${id}`,
        responseHandler: async (response) => await response.text(),
      }),
      providesTags: ['TenantLogo'],
    }),

    addNewTenant: build.mutation({
      query(data: CreateTenantRequestDto) {
        return {
          url: Tenant_Host_Route,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (rawResult: { data: Tenant }) => {
        return rawResult.data;
      },
      invalidatesTags: [invalidationTags.TENANT],
    }),

    uploadLogo: build.mutation({
      query(data: FormData) {
        return {
          url: `${Tenant_Host_Route}UploadLogo`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['TenantLogo'],
    }),

    EditTenant: build.mutation({
      query(data: UpdateTenantRequestDto) {
        return {
          url: Tenant_Host_Route,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: [invalidationTags.TENANT],
    }),

    PatchTenantStatus: build.mutation({
      query(payload: PatchTenantStatusDto) {
        return {
          url: Api.V1HostTenantsSetTenantStatusCreate.BaseRequest.url,
          method: Api.V1HostTenantsSetTenantStatusCreate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.TENANT],
    }),

    GetTenantOptions: build.query({
      query() {
        return {
          url: Api.V1HostTenantsTenantsOptionsList.BaseRequest.url,
          method: Api.V1HostTenantsTenantsOptionsList.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: { data: TenantOptionsDto }) => rawResult.data,
    }),

    GetDimColumnOptions: build.query({
      query: (input: Api.V1HostTenantsDimColumnOptionsDetail.PathParams) => ({
        url: Api.V1HostTenantsDimColumnOptionsDetail.BaseRequest.url,
        method: Api.V1HostTenantsDimColumnOptionsDetail.BaseRequest.method,
        params: {
          databaseName: input.databaseName,
          dbServer: input.databaseServer
        }
      }),
      transformResponse: (rawResult: string[]) => makeListSelectReady(rawResult),
    }),

    GetTenantUtilisation: build.query({
      query: (input: GetRequestFilter) => ({
        url: `${PowerBiApi_Host_Route}TenantUtilization`,
        method: 'GET',
        params: input,
      }),
      providesTags: [invalidationTags.TENANT],
    }),

    StartUsingStorageAccount: build.mutation({
      query: (payload: Api.V1HostTenantsMigrateToAzureStorageAccountCreate.RequestQuery) => ({
        url: Api.V1HostTenantsMigrateToAzureStorageAccountCreate.BaseRequest.url,
        method: Api.V1HostTenantsMigrateToAzureStorageAccountCreate.BaseRequest.method,
        params: payload,
      }),
      invalidatesTags: [invalidationTags.TENANT],
    }),

    DeleteTenant: build.mutation({
      query: (arg: Api.V1HostTenantsDelete.PathParams) => ({
        url: replacePathParams(Api.V1HostTenantsDelete.BaseRequest.url, arg),
        method: Api.V1HostTenantsDelete.BaseRequest.method,
      }),
      invalidatesTags: [invalidationTags.TENANT],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTenantByIdQuery,
  useGetTenantsQuery,
  useAddNewTenantMutation,
  useEditTenantMutation,
  useGetTenantUtilisationQuery,
  useGetTenantLogoByAccessTokenQuery,
  useDeleteTenantMutation,
  useUploadLogoMutation,
  useGetTenantLogoQuery,
  useGetTenantOptionsQuery,
  useGetDimColumnOptionsQuery,
  usePatchTenantStatusMutation,
  useStartUsingStorageAccountMutation,
  useGetTenantByIdNewQuery
} = tenantApi;
