import { type CellProps, type Column } from 'react-table';
import clsx from 'clsx';
import { DataTypesMap, StatusTypesMap } from '../api/types';
import { GetDateWith24hTime } from '../../../utility/tables/DateFormatter';
import ActionButton from '../../components/buttons/ActionButton';
import { getStatusColor, shouldDisableLogButton } from '../../../utility/Utils';
import { BiHubTaskRunLogDto } from '../../../api/data-contracts';

const biHubTaskRunColumnsDto = (openLogsModal: (id: number) => void): ReadonlyArray<Column<BiHubTaskRunLogDto>> => [
  {
    Header: 'status',
    id: 'status',
    Cell: ({ row, }: CellProps<BiHubTaskRunLogDto>) => (
      <span className={
        clsx(
          'badge',
          'success',
          getStatusColor(row.original.status)
        )}
      >
        {StatusTypesMap.get(row.original.status!)}
      </span >
    ),
  },
  {
    Header: 'Task name',
    accessor: 'name',
  },
  {
    Header: 'type',
    id: 'type',
    Cell: ({ row, }: CellProps<BiHubTaskRunLogDto>) => <span>{DataTypesMap.get(row.original.type!)}</span>,
  },
  {
    Header: 'Start time',
    id: 'startedAt',
    accessor: (d) => GetDateWith24hTime(d.startedAt!),
  },
  {
    Header: 'Finished time',
    id: 'finishedAt',
    accessor: (d) => GetDateWith24hTime(d.finishedAt!),
  },
  {
    Header: 'Error',
    id: 'error',
    accessor: (d) => d.error ?? '-',
  },
  {
    Header: 'Action',
    id: 'id',
    Cell: ({ row, }: CellProps<BiHubTaskRunLogDto>) => (
      <ActionButton
        text={'See logs'}
        onClick={() => {
          openLogsModal(row.original.id!);
        }}
        disabled={shouldDisableLogButton(row.original)}
        color={'secondary'}
      />
    ),
  }
];

export { biHubTaskRunColumnsDto };
