import { useEffect, useState } from 'react';
import { type Column, type Row, type SortingRule, useExpanded, useSortBy, useTable } from 'react-table';
import { type ItemsPerPage } from '../../../types';
import { CustomRow } from './CustomRow';
import clsx from 'clsx';
import { PaginationSearchParam } from '../pagination/PaginationSearchParam';
import { useSearchParams } from 'react-router-dom';
import { type FilterOptionDto } from '../../../api/data-contracts';
import FilterButton from '../../logs/FilterButton';
import { InputComponentUrlSearchParam } from '../inputs/InputComponentUrlSearchParam';

interface Props {
  col?: ReadonlyArray<Column<any>>;
  columns?: any;
  data: any[];
  totalCount: number;
  paginationState: any;
  showPagination?: boolean;
  isLoading?: boolean;
  renderRowSubComponent?: (row: Row<any>) => JSX.Element;
  isNestedTable?: boolean;
  dark?: boolean;
  fullHeight?: boolean;
  filters: FilterOptionDto[] | null | undefined;
  searchPlaceholder: string;
  headerButton?: JSX.Element;
  initialSortBy?: Array<SortingRule<object>>;
  disableHeader?: boolean;
};

function AppTableParamsV2({
  columns,
  data,
  totalCount,
  showPagination = true,
  isLoading = false,
  isNestedTable = false,
  renderRowSubComponent,
  dark = false,
  fullHeight = false,
  filters,
  searchPlaceholder,
  headerButton,
  initialSortBy = [],
  disableHeader = false,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const rowsPerPage = parseInt(searchParams.get('maxResultCount') ?? '10', 10);

  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
  const {
    getTableProps,
    getTableBodyProps,
    flatHeaders,
    rows,
    prepareRow,
    state: { sortBy, expanded, },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: isNestedTable,
      manualSortBy: true,
      initialState: {
        sortBy: initialSortBy,
        expanded: expandedRowIds.reduce((acc: any, rowId) => {
          acc[rowId] = true;
          return acc;
        }, {}),
      },
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    const currentExpandedRowIds = Object.keys(expanded).map(Number).filter((rowId) => expanded[rowId]);
    setExpandedRowIds(currentExpandedRowIds);
  }, [expanded]);

  useEffect(() => {
    if (!sortBy || sortBy.length < 1) return;

    // Read the current search parameters
    const currentParams = Object.fromEntries([...searchParams]);

    // Update the sorting parameter
    currentParams.sorting = `${sortBy[0].id} ${sortBy[0].desc ? 'desc' : 'asc'}`;

    // Update the search parameters in the URL
    setSearchParams(currentParams);
  }, [sortBy, setSearchParams]);

  const calculateSkipCount = (currentPage: number, pageSize: number) => {
    if (currentPage === 0) return 0;
    return (currentPage - 1) * pageSize;
  };

  const handlePageChange = (pageNumber: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const skipCount = calculateSkipCount(pageNumber, rowsPerPage);
    newSearchParams.set('page', pageNumber.toString());
    newSearchParams.set('maxResultCount', rowsPerPage.toString());
    newSearchParams.set('skipCount', skipCount.toString());
    setSearchParams(newSearchParams);
  };

  const handlePageSizeChange = (pageSize: ItemsPerPage) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const skipCount = calculateSkipCount(1, pageSize); // Since we're resetting to page 1
    newSearchParams.set('page', '1');
    newSearchParams.set('maxResultCount', pageSize.toString());
    newSearchParams.set('skipCount', skipCount.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <>
      {disableHeader ? null : (
        <div className="card-header border-0 pt-6">
          <div className="d-flex align-items-center position-relative my-1 gap-2">
            <InputComponentUrlSearchParam placeholder={searchPlaceholder} />
            <FilterButton filters={filters} />
          </div>

          {headerButton}
        </div>
      )}


      <div className='card-body border-0 pt-6'>
        <div className='overflow-auto'>
          <div className={clsx('', isLoading && 'overlay w-100 h-20')} style={{ height: fullHeight ? '100%' : '54vh', }}>
            <table className='table table-fixed align-middle gx-5 no-footer table-row-bordered' {...getTableProps()}>
              <thead className={clsx('bg-gray-200', dark && 'bg-primary')} style={{ position: 'sticky', top: '0', zIndex: '1', }}>
                <tr className={clsx('text-start fw-bold fs-7 text-uppercase gs-0', dark ? 'text-white' : 'text-primary')}>
                  {flatHeaders.map((column) => {
                    return (
                      /* eslint-disable-next-line react/jsx-key */
                      < th
                        className='w-150px' {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={column.id}
                      >
                        <div className="d-flex align-items-center ">
                          <span className="user-select-none">{column.render('Header')}</span>
                          <span className='ps-2 h-100 text-white'>
                            {column.isSorted
                              ? (
                                column.isSortedDesc ? <i className="fas fa-arrow-down text-primary"></i> : <i className="fas fa-arrow-up text-primary"></i>
                              )
                              : ''}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='text-gray-800 ' {...getTableBodyProps()}>
                {rows.length > 0
                  ? (
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <CustomRow
                          row={row}
                          key={`row-${i}-${row.id}`}
                          renderRowSubComponent={renderRowSubComponent}
                        />
                      );
                    })
                  )
                  : (
                    <tr>
                      <td colSpan={20}>
                        <div
                          className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>

            {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          </div>
        </div>

        {showPagination && (
          <PaginationSearchParam
            totalItemCount={totalCount}
            onPageChange={handlePageChange}
            onMaxPageSizeChange={handlePageSizeChange}
          />
        )}
      </div >
    </>
  );
}
export default AppTableParamsV2;
