import React from 'react';
import { useDialog } from '../../components/dialog/use-dialog';
import ActionButton from '../../components/buttons/ActionButton';
import { useDeleteTaskMutation, useGetTaskListQuery } from '../api/api';
import { DataTypesMap } from '../api/types';

interface TaskListPageProps {
	tenantId: number;
}

const TaskList: React.FC<TaskListPageProps> = ({ tenantId }) => {
	const { data, isFetching } = useGetTaskListQuery({ tenantId: tenantId });

	const [deleteTask, { isLoading }] = useDeleteTaskMutation();

	const { onOpen: openCreateTaskDialog } = useDialog('biHubTaskCreate');
	const { onOpen: openUpdateTaskDialog } = useDialog('biHubTaskUpdate');

	if (!data && isFetching) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		)
	}

	const handleDeleteTask = async (taskId: number) => {
		await deleteTask({ taskId });
	}

	return (
		<div>
			<div className="d-flex justify-content-end align-items-center">
				<ActionButton
					onClick={() => openCreateTaskDialog({ tenantId: tenantId, })}
					text='Add New Task'
					showPopConfirm={false}
					color='primary'
					fontIcon='fa fa-plus'
				/>
			</div>

			<div className="mt-4">
				<table className="table table-fixed align-middle gx-5 no-footer table-row-bordered striped border border-gray-200">
					<thead className='bg-gray-200'>
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Description</th>
							<th scope="col">Type</th>
							<th scope="col">Action</th>
							<th scope="col"></th>

						</tr>
					</thead>
					<tbody>

						{data?.map((task, index) => (
							<tr key={task.id}>
								<td>{task.name}</td>
								<td>{task.description}</td>
								<td>{DataTypesMap.get(task.type)}</td>
								<td>{task.action}</td>

								<td className='d-flex justify-content-end'>
									<ActionButton
										onClick={() => { openUpdateTaskDialog({ id: task.id, tenantId: tenantId, }) }}
										text='Edit'
										showPopConfirm={false}
										color='primary'
									/>

									<ActionButton
										onClick={() => handleDeleteTask(task.id)}
										text='Delete'
										showPopConfirm={true}
										confirmText='Are you sure you want to delete this pipeline?'
										color='danger'
										disabled={isLoading}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TaskList;