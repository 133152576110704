import { Link, useParams } from "react-router-dom";
import { Spinner, Tabs, Tab } from 'react-bootstrap';
import { RunPipelineForm } from "./forms/RunPipelineForm";
import { useGetPipelineQuery } from "./api/api";
import LogsAppTable from "./components/LogsApptable";
import { PipelineMoveTasks } from "./PipelineEditPage";
import { ListViewProvider } from "../../utility/ListView";

const PipelineDetailsPage = () => {
	const id = Number(useParams<{ id: string }>().id);

	const { data, isFetching } = useGetPipelineQuery(id);

	if (isFetching) {
		return (
			<div className="card-body d-flex justify-content-center">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		)
	}

	return (
		<>
			<div className="d-flex mb-3">
				<div className="d-flex w-100 justify-content-between">
					<div className="d-flex flex-column">
						<h1>{data?.name}</h1>
						<h4 className="text-muted">{data?.description}</h4>
					</div>

					<div>
						{data?.isEnabledForTenant ? (
							<span className="badge badge-success">Enabled for company</span>
						) : (
							<span className="badge badge-secondary">Disabled for company</span>
						)}
					</div>
				</div>
			</div>
			<div>
				<Tabs
					defaultActiveKey="refresh"
					className="mb-3"
				>
					<Tab eventKey="refresh" title="Refresh" >
						<>
							<RunPipelineForm tenantId={id} />

							{data &&
								<ListViewProvider>
									<LogsAppTable pipelineId={data?.id} />
								</ListViewProvider>
							}
						</>
					</Tab >
					<Tab eventKey="tasksMove" title="Move steps" >
						<PipelineMoveTasks tenantId={Number(data?.tenantId)} />
					</Tab >
				</Tabs >
			</div>
		</>
	)
};

export default PipelineDetailsPage;