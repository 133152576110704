import React from 'react';
import { useGetPipelineLogsQuery } from '../api/api';
import { biHubTaskRunColumnsDto } from '../management/_columnsExpanded';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import AppTableParamsV2 from '../../components/table/AppTableParamsV2';
import { biHubPipelineLogColumns } from '../management/_columnsPipelineLogs';
import { useRequestParams } from '../../../utility/hooks/hooks';
import { Row } from 'react-table';
import AppTable from '../../components/table/AppTable';
import { AppModalWrapper } from '../../components/modal/AppModalWrapper';
import LogViewer from './LogViewer';
import { useListView } from '../../../utility/ListView';

interface LogsAppTableProps {
	pipelineId: number | undefined;
}

const LogsAppTable: React.FC<LogsAppTableProps> = ({ pipelineId }) => {
	const requestParams = useRequestParams();
	const { isShowing, toggle } = useListView();

	const { data, isFetching, error } = useGetPipelineLogsQuery({
		input: {
			...requestParams,
			sorting: 'createdAt DESC',
			pipelineId: pipelineId,
		},
	});

	const columns = biHubTaskRunColumnsDto(toggle);

	// These are the nested bi hub tasks
	const renderRowSubComponent =
		(row: Row<any>) => (
			<AppTable
				totalCount={0}
				showPagination={false}
				data={row.original.biHubTaskRuns}
				columns={columns}
				dark
				isNestedTable
				fullHeight
			/>
		);

	let element = null;

	if (isFetching) {
		element = (
			<div className="d-flex justify-content-center">
				<div className="overlay-layer rounded bg-dark bg-opacity-5">
					<div
						className="spinner-border text-primary"
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		);
	} else if (data) {
		element = (
			<>
				<AppTableParamsV2
					initialSortBy={[
						{
							id: 'name',
							desc: false,
						}
					]}
					totalCount={data.totalCount}
					paginationState={requestParams}
					data={data.items}
					columns={biHubPipelineLogColumns}
					isLoading={isFetching}
					filters={undefined}
					searchPlaceholder={''}
					disableHeader={true}
					renderRowSubComponent={renderRowSubComponent}
				/>

				{isShowing && <AppModalWrapper title='Log' setShow={() => toggle(null)} modalProps={{ size: "lg", scrollable: false }} children={<LogViewer />} />}
			</>
		);
	} else if (error) {
		RtkErrorMessage('Fetching logs failed', error);
	}

	return (
		<div>
			{element}
		</div>
	);
};

export default LogsAppTable;