import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import RefreshPageListener from './utility/helpers/RefreshPageListener';

// Icons
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import { ModalProvider } from './views/components/dialog/modal-provider';

function App() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <RefreshPageListener />
      <LayoutProvider>
        <ModalProvider >
          <Outlet />
        </ModalProvider>
        <MasterInit />
      </LayoutProvider>
    </Suspense>
  );
}

export { App };
