import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TaskList from "./components/TaskList";
import PipelineList from "./components/PipelineList";
import { useEffect, useState } from "react";

const CompanyDetailsPage = () => {
	const id = Number(useParams<{ id: string }>().id);
	const paramActiveKey = useParams<{ activeKey: string }>().activeKey;

	const [activeKey, setActiveKey] = useState<string | null>();

	useEffect(() => {
		setActiveKey(paramActiveKey);
	}, [paramActiveKey]);

	return (
		<div className="d-flex justify-content-between mb-3">
			<div className="list-group w-100">
				<Tabs
					activeKey={activeKey ?? "pipelines"}
					onSelect={(k) => setActiveKey(k)}
					id="uncontrolled-tab-example"
					className="mb-3"
				>
					<Tab eventKey="pipelines" title="Pipeline">
						<PipelineList />
					</Tab >

					<Tab eventKey="tasks" title="Tasks" >
						<TaskList tenantId={id} />
					</Tab >
				</Tabs >
			</div>
		</div>
	)
};


export default CompanyDetailsPage;