import { BiHubPipelineDto, BiHubPipelineRunLogDto, BiHubTaskRunLogDto } from "../../../api/data-contracts";
import { BiHubPipeline, DataTypesMap } from "./types";

export function transformResponseBiHubPipelineDto(rawResult: BiHubPipelineDto): BiHubPipelineDto {
	const combinedTasks = rawResult.tasks ?? [];

	if (rawResult.powerBiDatasets !== null) {
		rawResult.powerBiDatasets?.sort((a, b) => a.powerBiReportDisplayName.localeCompare(b.powerBiReportDisplayName))
			.forEach((dataSet) => {
				combinedTasks.push({
					id: dataSet.id,
					name: dataSet.powerBiReportDisplayName,
					type: 6,
					databaseName: '',
					sorting: undefined,
					action: '',
					step: -1,
				});
			});
	}

	rawResult.tasks = combinedTasks;
	return rawResult;
}

export function transformGetPipelinesLogsResponse(rawResult: { data: { totalCount: number; items: BiHubPipelineRunLogDto[] } }) {
	rawResult.data.items.forEach((item) => {
		if (item.biHubPowerBiDatasetRefreshes) {
			const enrichedBiHubPowerBiDatasetRefreshes = item.biHubPowerBiDatasetRefreshes.map((refresh: any) => ({
				...refresh,
				action: 'Power BI dataset refresh',
				name: refresh.reportName,
				type: 6,
			}));

			const biHubtaskRuns = item.biHubTaskRuns ?? [];
			item.biHubTaskRuns = biHubtaskRuns.concat(enrichedBiHubPowerBiDatasetRefreshes);
		}
	});

	return rawResult.data;
}

const parseCronExpression = (cronExpression: string) => {
	const [specificMinutes, specificHours, , , recurringDays] = cronExpression.split(' ');
	return {
		specificMinutes: specificMinutes,
		specificHours: specificHours.split(','),  // Convert to array of numbers
		recurringDays: recurringDays.split(',').map(Number),  // Convert to array of numbers
	};
};

export function transformGetPipeline(rawResult: BiHubPipelineDto): BiHubPipeline {
	if (!rawResult.cronExpression) {
		return {
			...rawResult,
			specificMinutes: '',
			specificHours: [],
			recurringDays: [],
		}
	}

	const { specificMinutes, specificHours, recurringDays } = parseCronExpression(rawResult.cronExpression);

	return {
		...rawResult,
		specificMinutes,
		specificHours,
		recurringDays,
	};
};

export const transformPipelineTaskResponse = (response: any) => {
	response.sequentialTasks = [];

	if (response.tasks) {
		// the tasks get split here, so we can display them in two different tables
		const sequentialTasks: any = [];
		response.tasks = response.tasks.map((task: any) => {
			task.type = DataTypesMap.get(task.type);
			if (!task.runParallel) {
				sequentialTasks.push(task);
				return null; // Mark the task for removal
			}
			return task;
		}).filter((task: any) => task !== null); // Remove marked tasks
		response.sequentialTasks = sequentialTasks;
	} else {
		// Create an empty list so ag-grid can handle it
		response.tasks = [];
	}

	return response;
};
