import * as Permission from '../../constants/permissions';
import Page from '../components/Page';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { AppRoutes } from '../../routing/routeNames';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import './styles/rc-tree-style.css';
import { useGetTenantsAndPipelinesQuery } from './api/api';
import { BiHubTenantDto } from '../../api/data-contracts';
import clsx from 'clsx';
import { getStatusColor } from '../../utility/Utils';

const BreadCrumbs: PageLink[] = [
  {
    title: AppRoutes.biHubPage.userFriendlyName,
    path: AppRoutes.biHubPage.path,
    isSeparator: true,
    isActive: false,
  }
];

export namespace BiHubConst {
  export const COMPANY = 'company';
}

function BiHubPageTree() {
  const [searchParams, setSearchParams] = useState("");
  const navigate = useNavigate();
  const { data, isLoading } = useGetTenantsAndPipelinesQuery({});

  if (data === undefined || isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  const filteredListItems = data.filter((item: BiHubTenantDto) => item.name.toLowerCase().includes(searchParams.toLowerCase()));

  const treeData = filteredListItems.map((tenant: BiHubTenantDto) => (
    {
      title: tenant.name,
      key: `${BiHubConst.COMPANY}-${tenant.id}`,
      icon: <i className="fa fa-building " />,
      children:
        [
          {
            title: "Pipelines",
            key: `pipelines-${tenant.id}`,
            children: !tenant.pipelines ?
              [] :
              tenant.pipelines.map((pipeline) => ({
                title: (
                  <div className='d-flex w-100 justify-content-between pe-5'>
                    <div>{pipeline.name}</div>
                    <div>
                      <span className={clsx(
                        'badge',
                        getStatusColor(pipeline.status)
                      )}
                      >
                        ~
                      </span>
                    </div>
                  </div>
                ),
                icon: <i className="fa fa-tasks" />,
                key: `pipeline-${pipeline.id}`,
                isLeaf: true,
              })),
          },
          {
            title: "Tasks",
            key: `tasks-${tenant.id}`,
            children: !tenant.tasks ?
              [] :
              tenant.tasks.map((task) => ({
                title: task.name,
                key: `task-${task.id}-${tenant.id}`,
                isLeaf: true,
                selectable: false,
              })),
          }
        ]
    })
  );

  const handleSelect = (selectedKeys: any[]) => {
    if (selectedKeys.length === 0) return;

    const selectedKey = selectedKeys[0];
    const [type, idStr] = selectedKey.split('-');

    if (type === "company") {
      navigate(`${AppRoutes.companyPage.path}/${idStr}`);
    } else if (type === "pipeline") {
      navigate(`${AppRoutes.pipelinePage.path}/${idStr}`);
    } else if (type === "pipelines") {
      navigate(`${AppRoutes.companyPage.path}/${idStr}/pipelines`);
    } else if (type === "tasks") {
      navigate(`${AppRoutes.companyPage.path}/${idStr}/tasks`);
    }
  };

  return (
    <Page title="" permission={Permission.Host_BiHub_View} >
      <PageTitle breadcrumbs={BreadCrumbs}>{AppRoutes.biHubPage.userFriendlyName}</PageTitle>

      <div className="row h-100">
        <div className="col-3 p-4 bg-white border d-flex flex-column gap-2">
          <div className="">
            <input
              type="text"
              className="form-control"
              placeholder="Search Pipelines..."
              value={searchParams}
              onChange={(e) => setSearchParams(e.target.value)}
            />
          </div>

          <div className="rounded flex-grow-1 overflow-auto p-4 bg-gray-50">
            <Tree
              treeData={treeData}
              selectable
              onSelect={handleSelect}
            />

            {filteredListItems.length === 0 && (
              <div className="d-flex justify-content-center align-items-center h-100">
                <h1 className="text-muted">No pipelines found</h1>
              </div>
            )}
          </div>
        </div>

        <div className="col-9 bg-white p-4">
          <Outlet />
        </div>
      </div>
    </Page>
  );
}


export default BiHubPageTree;
