import auth from './authentication';
import { emptySplitApi } from '../utility/emptySplitApi';
import layout from './layout';
import backToContentManagerPage from './BackToContentManagerPage';
import uiReducer from './uireducer';

const rootReducer = {
  auth,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  layout,
  backToContentManagerPage,
  uiReducer
};

export default rootReducer;
