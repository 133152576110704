
import { Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../redux/actions';
import { isModalOpen, getModalMeta } from '../../../redux/selectors';
import { UIState } from '../../../redux/uireducer';
import { ModalMeta } from '../../../types/modal';

interface ILazyComponentProps {
    filename: string;
}

export function LazyComponent({ filename }: ILazyComponentProps) {
    const dispatch = useDispatch();
    const isOpen = useSelector<UIState, boolean>((state) =>
        isModalOpen(state, filename)
    );
    const meta = useSelector<UIState, ModalMeta | undefined>((state) =>
        getModalMeta(state, filename)
    );

    const handleModalClose = () => {
        dispatch(closeModal(filename));
    };

    const Component = lazy(() => import(`./${filename}/${filename}.tsx`));

    return (
        <Suspense fallback={null}>
            {filename ? (
                <Component isOpen={isOpen} onClose={handleModalClose} {...meta} />
            ) : null}
        </Suspense>
    );
}
