import { useState } from 'react';
import { BiHubTaskDto } from '../../../api/data-contracts';

export interface SelectRowsHook {
  selectedRows: BiHubTaskDto[];
  handleSelectAll: (rows: BiHubTaskDto[]) => void;
  handleSelectRow: (id: BiHubTaskDto) => void;
  deselectAll: () => void;
}

export const useSelectRows = (): SelectRowsHook => {
  const [selectedRows, setSelectedRows] = useState<BiHubTaskDto[]>([]);

  const handleSelectAll = (rows: BiHubTaskDto[]) => {
    const allIds = rows.map((row) => row);
    setSelectedRows(allIds);
  };

  const deselectAll = () => {
    setSelectedRows([]);
  };

  const handleSelectRow = (id: BiHubTaskDto) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  return {
    selectedRows, handleSelectAll, handleSelectRow, deselectAll,
  };
};
