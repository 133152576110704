import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormatDateWithoutCurrentYear } from '../../../../app/utility/tables/DateFormatter';
import IsRouteOrActionAllowed from '../../../../app/utility/IsRouteOrActionAllowed';
import { Tenant_BiHub_Run } from '../../../../app/constants/permissions';
import { useGetBiHubStatusQuery, useRunPipelineForCurrentTenantMutation } from '../../../../app/views/bi-hub/api/api';
import AppNotification from '../../../../app/utility/notifications/AppNotifications';
import { getUserData } from '../../../../app/utility/Utils';
import { RunBiHubPipelineFromTenantRequestDto } from '../../../../app/api/data-contracts';

interface Props {
  userId: number;
}

function AsideMenuBiHubStatus({ userId, }: Props) {
  const [pollingInterval, setPollingInterval] = useState<undefined | number>(undefined);

  const { data, isLoading, } = useGetBiHubStatusQuery(userId, {
    pollingInterval,
  });

  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    if (data) {
      if (data.isEnabledForTenant) {
        // Here we set the polling interval for users with run rights to 1 minute and
        // for default users to 5 minutes and else disable polling to save bandwidth
        setPollingInterval(IsRouteOrActionAllowed.IsRouteOrActionAllowed(Tenant_BiHub_Run) ? 6000 : 30000);
      }

      if (data.lastPipelineCreated && data.lastPipelineCreated) {
        function computeElapsedTime() {
          const startDate = new Date(data!.lastPipelineCreated!.toString());
          const now = new Date();

          const differenceInSeconds = (now.getTime() - startDate.getTime()) / 1000;
          const minutes = Math.floor(differenceInSeconds / 60);
          const seconds = Math.floor(differenceInSeconds % 60);

          return minutes > 0 ? `Data update started ${minutes}m ${seconds}s ago` : `Data update started  ${seconds}s ago`;
        }

        const interval = setInterval(() => {
          setElapsedTime(computeElapsedTime());
        }, 100);

        // Initial set
        setElapsedTime(computeElapsedTime());

        return () => { clearInterval(interval); };
      }
    }
  }, [data]);

  const [runPipeline, { isLoading: runPipelineLoading, }] = useRunPipelineForCurrentTenantMutation();

  const runBiHub = async () => {
    const { userId, } = getUserData();

    const payload: RunBiHubPipelineFromTenantRequestDto = {
      userId: userId,
      pipelineId: data!.id,
    };

    await runPipeline(payload)
      .unwrap()
      .then(() => {
        AppNotification.info('Data refresh', 'Refresh has started!');
      })
      .catch(async (error) => {
        if (error.data.messages && error.data.messages.length > 0) {
          AppNotification.error(error.data.exception, error.data.messages[0]);
        } else {
          AppNotification.error(error.data.exception);
        }
      });
  };

  return (
    data && !isLoading && data.isEnabledForTenant
      ? (
        <div
          className="d-flex flex-column justify-content-between align-items-center"
          style={{ position: 'relative', }}
        >
          <div
            className="d-flex flex-column align-items-center mt-2"
          >
            {data.lastUpdateDate
              ? (
                <div className="mx-3 text-center w-100">
                  <div className="text-gray-400">Most recent data:</div>
                  <div className="text-gray-200 fs-4">{FormatDateWithoutCurrentYear(data.lastUpdateDate)}</div>
                </div>
              )
              : null}
          </div>

          {IsRouteOrActionAllowed.IsRouteOrActionAllowed(Tenant_BiHub_Run) && data.isEnabledForTenant &&
            (
              <div>
                <button
                  className={clsx('btn btn-primary btn-sm btn-flex px-4 me-2', runPipelineLoading || data.pipelineIsRunning && 'disabled')}
                  onClick={runBiHub}
                  type="button"
                >
                  {(() => {
                    switch (true) {
                      case (!runPipelineLoading && !data.pipelineIsRunning):
                        return (
                          <span className="indicator-label">
                            <i className="fs-3 me-3 fas fa-refresh" />
                            Update data
                          </span>
                        );
                      case runPipelineLoading:
                        return (
                          <span className="indicator-progress" style={{ display: 'block', }}>
                            <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true" />
                            Please wait...
                          </span>
                        );
                      case data.pipelineIsRunning:
                        return (
                          <span className="indicator-progress" style={{ display: 'block', }}>
                            <span className="spinner-border spinner-border-sm align-middle me-2" />
                            {elapsedTime}
                          </span>
                        );
                      default:
                        return null;
                    }
                  })()}
                </button>
                <div className="mt-1 text-center w-100">
                  <div className="text-gray-400 fs-8">{data.lastUpdatedExplanationText}</div>
                </div>
              </div>
            )}
        </div>
      )
      : null
  );
}

export default AsideMenuBiHubStatus;
