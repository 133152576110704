import clsx from 'clsx';
import { type Column } from 'react-table';
import { StatusTypesMap } from '../api/types';
import { getStatusColor } from '../../../utility/Utils';
import { GetDateWith24hTime } from '../../../utility/tables/DateFormatter';
import { BiHubPipelineRunLogDto } from '../../../api/data-contracts';

const biHubPipelineLogColumns: ReadonlyArray<Column<BiHubPipelineRunLogDto>> = [
  {
    id: 'expander',
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, }: any) => (
      <span {...getToggleAllRowsExpandedProps()}>
        <button type={'button'} className="btn btn-icon btn-sm btn-light h-">
          {isAllRowsExpanded
            ? <i className="bi bi-dash-circle text-primary" />
            : <i className="bi bi-plus-circle text-primary" />}
        </button>

      </span>
    ),
    Cell: ({ row, }: any) => (
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
      >
        <button type={'button'} className="btn btn-icon btn-sm btn-light h-">
          {row.isExpanded
            ? <i className="bi bi-dash-circle text-primary" />
            : <i className="bi bi-plus-circle text-primary" />}
        </button>
      </span>
    ),
  },
  {
    Header: 'status',
    id: 'status',
    Cell: ({ ...props }) => (
      <span className={clsx(
        'badge',
        getStatusColor(props.data[props.row.index].status)
      )}
      >
        {StatusTypesMap.get(props.data[props.row.index].status)}
      </span>
    ),
  },
  {
    Header: 'Tenant name',
    disableSortBy: true,
    accessor: 'tenantName',
  },
  {
    Header: 'Started by',
    accessor: 'masterUserEmail',
  },
  {
    Header: 'Start time',
    id: 'startedAt',
    accessor: (d) => GetDateWith24hTime(d.startedAt!),
  },
  {
    Header: 'Finished time',
    id: 'finishedAt',
    accessor: (d) => GetDateWith24hTime(d.finishedAt!),
  },
  {
    Header: 'Started from company',
    disableSortBy: true,
    accessor: (d) => <div className="badge my-2 badge-light d-inline-block mx-1">{d.biHubExecutedBy}</div>,
  },
];

export { biHubPipelineLogColumns };
