import { useFormikContext } from 'formik';
import { Select, SelectProps } from 'antd';
import AppFormLabel from './AppFormLabel';
import AppFormError from './AppFormError';
import { objectByString } from '../../../utility/Utils';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  options: Array<{ label: string; value: string | number }>;
  loading: boolean;
  onChange?: (event: number | string) => void;
  mode?: 'multiple' | 'tags';
  isFilterSortEnabled?: boolean;
  disabled?: boolean;
  customValueSortFunction?: (values: Array<string | number>) => Array<string | number>;
  dropdownProps?: SelectProps;
  onSearchChange?: (newKeyword: string) => void
}

function AppFormDropdownAnt({
  options,
  name,
  label,
  loading,
  required = false,
  disabled = false,
  isFilterSortEnabled = true,
  mode,
  customValueSortFunction,
  onChange,
  dropdownProps,
  onSearchChange,
}: Props): JSX.Element {
  const { setFieldValue, errors, values, } = useFormikContext();

  const handleChange = (value: number | string | Array<number | string>) => {
    // If a custom sort function is provided and the mode allows multiple selections, apply it
    if (customValueSortFunction && mode === 'multiple' && Array.isArray(value)) {
      const sortedValues = customValueSortFunction(value);
      setFieldValue(name, sortedValues);
    } else {
      setFieldValue(name, value);
    }

    if (onChange && !Array.isArray(value)) {
      onChange(value);
    }
  };

  return (
    <>
      <AppFormLabel label={label} required={required} />
      <Select
        size="large"
        disabled={disabled}
        mode={mode}
        showSearch
        loading={loading}
        className="w-100"
        id="floatingSelect"

        filterOption={(input, option) => (option?.label ? option.label.toString().toLowerCase() : '').includes(input.toLowerCase())}
        filterSort={isFilterSortEnabled
          ? (optionA, optionB) => (optionA?.label ?? '').toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toString().toLowerCase())
          : undefined}

        value={!loading && objectByString(values, name)}
        options={options}
        onChange={handleChange}
        onSearch={onSearchChange}
        {...dropdownProps}
      />
      <AppFormError
        visible={objectByString(errors, name)}
        error={objectByString(errors, name)}
      />
    </>
  );
}

export default AppFormDropdownAnt;
