import { emptySplitApi, invalidationTags } from '../../../utility/emptySplitApi';
import { BiHub_Host_Route, BiHub_Tenant_Route } from '../../../constants/routes';
import { Api } from '../../../api/ApiRoute';
import { replacePathParams, setUrlParams } from '../../../utility/Utils';
import { BiHubPipelineDto, BiHubPipelineRunLogDto, BiHubPipelineSettingsDto, BiHubStatusDto, BiHubTaskDto, BiHubTenantDto, CreateBiHubPipelineRequestDto, FileContentResultResult, PowerBiDropDownDto, PowerBiDropDownDtoResult, RunBiHubPipelineFromHostRequestDto, UpdateBiHubPipelineRequestDto, UpdateBiHubPipelineTasksRequestDto, UpdateBiHubTaskRequestDto } from '../../../api/data-contracts';
import { transformGetPipeline, transformGetPipelinesLogsResponse, transformPipelineTaskResponse, transformResponseBiHubPipelineDto } from './dataTransform';

const BiHubAPI = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getPowerBiDropdown: build.query({
      query(input: Api.V1HostPowerBiDropdownDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostPowerBiDropdownDetail.BaseRequest.url, input),
          method: Api.V1HostPowerBiDropdownDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: PowerBiDropDownDtoResult) => rawResult.data?.reports
        ? rawResult.data?.reports.map((report) => ({ label: report.name, value: report.id }))
        : [],
    }),


    //New routes
    getTenantBiHubSettings: build.query({
      query(input: Api.V1HostBiHubTenantBiHubSettingsDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubTenantBiHubSettingsDetail.BaseRequest.url, input),
          method: Api.V1HostBiHubTenantBiHubSettingsDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubPipelineSettingsDto) => rawResult,
      providesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    updateTenantBiHubSettings: build.mutation({
      query(payload: Api.V1HostBiHubTenantBiHubSettingsUpdate.RequestBody) {
        return {
          url: Api.V1HostBiHubTenantBiHubSettingsUpdate.BaseRequest.url,
          method: Api.V1HostBiHubTenantBiHubSettingsUpdate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    getTenantsAndPipelines: build.query({
      query() {
        return {
          url: Api.V1HostBiHubTenantsList.BaseRequest.url,
          method: Api.V1HostBiHubTenantsList.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubTenantDto[]) => rawResult,
      providesTags: [invalidationTags.TENANT, invalidationTags.BIHUB_PIPELINE],
    }),

    getPipelinesTenantId: build.query({
      query(input: Api.V1HostBiHubPipelinesDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubPipelinesDetail.BaseRequest.url, input),
          method: Api.V1HostBiHubPipelinesDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubPipelineDto[]) => rawResult,
      providesTags: [invalidationTags.TENANT, invalidationTags.BIHUB_PIPELINE],
    }),

    updatePipeline: build.mutation({
      query(payload: UpdateBiHubPipelineRequestDto) {
        return {
          url: Api.V1HostBiHubPipelineUpdate.BaseRequest.url,
          method: Api.V1HostBiHubPipelineUpdate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    updatePipelineTasks: build.mutation({
      query(payload: UpdateBiHubPipelineTasksRequestDto) {
        return {
          url: Api.V1HostBiHubPipelineTasksUpdate.BaseRequest.url,
          method: Api.V1HostBiHubPipelineTasksUpdate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE, invalidationTags.BIHUB_TASK],
    }),

    createPipeline: build.mutation({
      query(payload: CreateBiHubPipelineRequestDto) {
        return {
          url: Api.V1HostBiHubPipelineCreate.BaseRequest.url,
          method: Api.V1HostBiHubPipelineCreate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    deleteTask: build.mutation({
      query(payload: Api.V1HostBiHubTaskDelete.RequestQuery) {
        return {
          url: setUrlParams(Api.V1HostBiHubTaskDelete.BaseRequest.url, payload),
          method: Api.V1HostBiHubTaskDelete.BaseRequest.method,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_TASK],
    }),

    deletePipeline: build.mutation({
      query(payload: Api.V1HostBiHubPipelineDelete.RequestQuery) {
        return {
          url: setUrlParams(Api.V1HostBiHubPipelineDelete.BaseRequest.url, payload),
          method: Api.V1HostBiHubPipelineDelete.BaseRequest.method,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    createOrUpdateTask: build.mutation({
      query(payload: UpdateBiHubTaskRequestDto) {
        return {
          url: Api.V1HostBiHubTaskUpdate.BaseRequest.url,
          method: Api.V1HostBiHubTaskUpdate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_TASK, invalidationTags.BIHUB_PIPELINE],
    }),

    getTask: build.query({
      query(payload: Api.V1HostBiHubTaskDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubTaskDetail.BaseRequest.url, payload),
          method: Api.V1HostBiHubTaskDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubTaskDto) => rawResult,
      providesTags: [invalidationTags.BIHUB_TASK],
    }),

    getTaskList: build.query({
      query(pathParams: Api.V1HostBiHubTasksDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubTasksDetail.BaseRequest.url, pathParams),
          method: Api.V1HostBiHubTasksDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubTaskDto[]) => rawResult,
      providesTags: [invalidationTags.BIHUB_TASK],
    }),

    getPipeline: build.query({
      query(pipelineId: number) {
        return {
          url: replacePathParams(Api.V1HostBiHubPipelineDetail.BaseRequest.url, { pipelineId: pipelineId }),
          method: Api.V1HostBiHubPipelineDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubPipelineDto) => transformGetPipeline(rawResult),
      providesTags: [invalidationTags.BIHUB_PIPELINE],
    }),

    runPipeline: build.mutation({
      query(arg: { payload: RunBiHubPipelineFromHostRequestDto }) {
        return {
          url: Api.V1HostBiHubRunCreate.BaseRequest.url,
          method: Api.V1HostBiHubRunCreate.BaseRequest.method,
          body: arg.payload,
        };
      },
      invalidatesTags: [invalidationTags.BIHUB_PIPELINE_LOGS],
    }),

    getPipelineLogs: build.query({
      query(arg: { input: any; }) {
        return {
          url: Api.V1HostBiHubLogsList.BaseRequest.url,
          method: Api.V1HostBiHubLogsList.BaseRequest.method,
          params: arg.input,
        };
      },
      transformResponse: (rawResult: { data: { totalCount: number; items: BiHubPipelineRunLogDto[] } }) => transformGetPipelinesLogsResponse(rawResult),
      providesTags: ['BiHubPipelineLogs'],
    }),

    getBiHubStatus: build.query({
      query() {
        return {
          url: Api.V1TenantBiHubStatusList.BaseRequest.url,
          method: Api.V1TenantBiHubStatusList.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: { data: BiHubStatusDto }) => rawResult.data,
      providesTags: ['BiHubTenantStatus'],
    }),

    //Delete routes
    getPipelineTenants: build.query({
      query() {
        return {
          url: `${BiHub_Host_Route}tenants`,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: string[]) => rawResult,
      providesTags: ['Tenant'],
    }),

    getPipelineForRunPipeline: build.query({
      query(input: Api.V1HostBiHubPipelineDetail.PathParams) {
        return {
          url: replacePathParams(Api.V1HostBiHubPipelineDetail.BaseRequest.url, input),
          method: Api.V1HostBiHubPipelineDetail.BaseRequest.method,
        };
      },
      transformResponse: (rawResult: BiHubPipelineDto) => transformResponseBiHubPipelineDto(rawResult),
      providesTags: [],
    }),

    getPipelineTransformed: build.query({
      query(tenantId: number) {
        return {
          url: BiHub_Host_Route + tenantId,
          method: 'GET',
        };
      },
      transformResponse: (rawResult: any): BiHubPipelineDto => transformPipelineTaskResponse(rawResult),
      providesTags: ['BiHubPipeline'],
    }),

    runPipelineForCurrentTenant: build.mutation({
      query(payload: Api.V1TenantBiHubRunCreate.RequestBody) {
        return {
          url: Api.V1TenantBiHubRunCreate.BaseRequest.url,
          method: Api.V1TenantBiHubRunCreate.BaseRequest.method,
          body: payload,
        };
      },
      invalidatesTags: ['BiHubTenantStatus'],
    }),
    getLogsPreview: build.query({
      query: (input: Api.V1HostBiHubLogsPreviewLogDetail.PathParams) => ({
        url: replacePathParams(Api.V1HostBiHubLogsPreviewLogDetail.BaseRequest.url, input),
        method: Api.V1HostBiHubLogsPreviewLogDetail.BaseRequest.method,
      }),
      transformResponse: (data: FileContentResultResult) => data,
    }),
  }),
});

export const {
  useGetPowerBiDropdownQuery,
  useUpdatePipelineTasksMutation,
  useLazyGetPipelineLogsQuery,
  useGetPipelineLogsQuery,
  useGetPipelineTenantsQuery,
  useGetBiHubStatusQuery,
  useLazyGetPipelineQuery,
  useGetPipelineQuery,
  useLazyGetPipelineForRunPipelineQuery,
  useUpdatePipelineMutation,
  useRunPipelineMutation,
  useGetPipelineTransformedQuery,
  useRunPipelineForCurrentTenantMutation,
  useGetLogsPreviewQuery,
  useGetPipelinesTenantIdQuery,
  useCreatePipelineMutation,
  useDeletePipelineMutation,
  useDeleteTaskMutation,
  useGetTenantsAndPipelinesQuery,
  useCreateOrUpdateTaskMutation,
  useGetTaskListQuery,
  useGetTaskQuery,
  useGetTenantBiHubSettingsQuery,
  useUpdateTenantBiHubSettingsMutation,
} = BiHubAPI;
