import type { UIState } from './uireducer';
import { createSelector } from '@reduxjs/toolkit';

const selectModalState = (state: UIState) => state.uiReducer.modal;

export const getOpenModalsList = createSelector(
    [selectModalState],
    (modalState) => Object.keys(modalState).filter((modalId) => modalState[modalId].open)
);


export const isModalOpen = createSelector(
    [selectModalState, (_state: UIState, id: string) => id],
    (modalState, id) => modalState[id]?.open ?? false
);

export const getModalMeta = createSelector(
    [selectModalState, (_state: UIState, id: string) => id],
    (modalState, id) => modalState[id]?.meta
);