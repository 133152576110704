import { useSelector } from 'react-redux';
import { LazyComponent } from './lazy-component';
import { getOpenModalsList } from '../../../redux/selectors';
import { useMemo } from 'react';


interface IModalProviderProps {
    children: React.ReactNode;
}

export function ModalProvider(props: IModalProviderProps) {
    const modals = useSelector(getOpenModalsList);

    // Memoize the rendered modals list to avoid unnecessary re-renders
    const modalsList = useMemo(() => {
        return modals.map((filename) => (
            <LazyComponent key={filename} filename={filename} />
        ));
    }, [modals]);

    return (
        <>
            {modalsList}

            {props.children}
        </>
    );
}
