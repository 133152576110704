import React from 'react';
import { BiHubTaskDto } from '../../../api/data-contracts';
import { DataTypesMap } from '../api/types';

interface Props {
  rows: BiHubTaskDto[];
  selectedRows: BiHubTaskDto[];
  handleSelectAll: (rows: BiHubTaskDto[]) => void;
  deselectAll: () => void;
  handleSelectRow: (row: BiHubTaskDto) => void;
}

function CheckableTable({
  rows, selectedRows, deselectAll, handleSelectAll, handleSelectRow,
}: Props) {
  return (
    <table
      className="table align-middle table-row-dashed dataTable gx-5 no-footer table-striped my-4"
    >
      <thead className="bg-gray-200">
        <tr>
          <th>
            <input
              type="checkbox"
              className="form-check-input"
              checked={selectedRows.length === rows.length}
              onChange={() => { selectedRows.length === rows.length ? deselectAll() : handleSelectAll(rows); }}
            />
          </th>
          <th>Name</th>
          <th>Type</th>
          <th>Action</th>
          <th>Database</th>
          <th>Step</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <td>
              <input
                type="checkbox"
                className="form-check-input"
                checked={selectedRows.includes(row)}
                onChange={() => { handleSelectRow(row); }}
              />
            </td>
            <td>{row.name}</td>
            <td>{DataTypesMap.get(row.type!)}</td>
            <td>{row.action}</td>
            <td>{row.databaseName}</td>
            <td>{row.type !== 6 ? row.step + 1 : "Last"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CheckableTable;
