import * as Permission from '../../constants/permissions';
import Page from '../components/Page';
import { type PageLink, PageTitle } from '../../../_metronic/layout/core';
import ListWrapper from './management/ListWrapper';
import { AppRoutes } from '../../routing/routeNames';

const BreadCrumbs: PageLink[] = [
    {
        title: AppRoutes.allPipelineRuns.userFriendlyName,
        path: AppRoutes.allPipelineRuns.path,
        isSeparator: false,
        isActive: false,
    },
    {
        title: AppRoutes.biHubPage.userFriendlyName,
        path: AppRoutes.biHubPage.path,
        isSeparator: true,
        isActive: false,
    }
];

function AllPipelineRunsPage() {
    return (
        <Page title="" permission={Permission.Host_BiHub_View} >
            <PageTitle breadcrumbs={BreadCrumbs}>{AppRoutes.biHubPage.userFriendlyName}</PageTitle>
            <ListWrapper />
        </Page>
    );
}

export default AllPipelineRunsPage;
