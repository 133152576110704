import { createReducer } from '@reduxjs/toolkit';
import { ModalOpenAction, ModalCloseAction, MODAL_OPEN, MODAL_CLOSE } from './actions';
import { ModalMap } from '../types/modal';

export interface UIState {
    uiReducer: {
        modal: ModalMap;
    };
}

export interface ModalState {
    modal: ModalMap;
}

const initialState: ModalState = {
    modal: {}
};
const uiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(MODAL_OPEN, (state, action: ModalOpenAction) => {
            const id = action.payload.modalFileName;
            const meta = action.payload.meta;
            state.modal[id] = { id, meta, open: true };
        })
        .addCase(MODAL_CLOSE, (state, action: ModalCloseAction) => {
            const id = action.payload.modalFileName;
            state.modal[id] = { id, open: false };
        });
});

export default uiReducer;