import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

interface Props {
  setShow: () => void;
  children: React.ReactNode;
  title: string;
  disableOnHideOnClick?: boolean;
  modalProps?: ModalProps;
}

function AppModalWrapper({
  setShow, children, title, disableOnHideOnClick = false, modalProps
}: Props) {
  return (
    <Modal
      {...modalProps}
      tabIndex={-1}
      aria-hidden="true"
      show
      dialogClassName="modal-dialog modal-dialog-centered "
      onHide={setShow}
      contentClassName="bg-transparent"
      backdrop={disableOnHideOnClick ? 'static' : true}
    >
      <div className="modal-content">
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {children}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export { AppModalWrapper };
