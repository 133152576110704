import React, { useEffect, useState } from 'react';
import { useGetLogsPreviewQuery } from '../api/api';
import { useListView } from '../../../utility/ListView';
import { FileContentResultResult } from '../../../api/data-contracts';

interface ExtendedFileContentResultResult extends FileContentResultResult {
	fileContents: string;
}

function LogViewer() {
	const { id } = useListView();

	if (!id) {
		return <>No ID provided</>;
	}

	const { data, isFetching, error } = useGetLogsPreviewQuery({ id });
	const [content, setContent] = useState('');
	const [filteredContent, setFilteredContent] = useState('');
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if (!data || !data.data) {
			return;
		}

		const fileContents = (data.data as any as ExtendedFileContentResultResult).fileContents;
		const decodedContent = window.atob(fileContents);
		setContent(decodedContent);
		setFilteredContent(decodedContent); // initially, display all content
	}, [data]);

	useEffect(() => {
		if (searchTerm === '') {
			setFilteredContent(content); // no search term means show all content
		} else {
			const lines = content.split('\n');
			const filteredLines = lines.filter(line => line.toLowerCase().includes(searchTerm.toLowerCase()));
			setFilteredContent(filteredLines.join('\n'));
		}
	}, [searchTerm, content]);

	if (isFetching) {
		return <div className="d-flex justify-content-center">
			<div className="overlay-layer rounded bg-dark bg-opacity-5">
				<div className="spinner-border text-primary" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>;
	}

	if (error) {
		return <div className="d-flex justify-content-center">No log file found for this task</div>;
	}

	return (
		<div>
			<input
				type="text"
				className="form-control mb-3"
				placeholder="Search in logs..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<div className="p-3  border">
				<pre className="mb-0">{filteredContent}</pre>
			</div>
		</div>
	);
}

export default LogViewer;