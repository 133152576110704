import * as React from 'react';
import { useEffect, useState } from 'react';
import { KTCard } from '../../../../_metronic/helpers';
import { type GetRequestFilter } from '../../../types';
import { requestFilter } from '../../../utility/tables/defaultRequestFilter';
import AppTable from '../../components/table/AppTable';
import { biHubTaskRunColumnsDto } from './_columnsExpanded';
import { useLazyGetPipelineLogsQuery } from '../api/api';
import { type Row } from 'react-table';
import { biHubPipelineLogColumns } from './_columnsPipelineLogs';
import { RtkErrorMessage } from '../../../utility/notifications/Message';
import { useListView, ListViewProvider } from '../../../utility/ListView';
import { AppModalWrapper } from '../../components/modal/AppModalWrapper';
import LogViewer from '../components/LogViewer';

function List() {
    const { isShowing, toggle } = useListView();
    const [paginationState, setPaginationState] = React.useState<GetRequestFilter>({
        ...requestFilter,
        sorting: 'createdAt DESC',
    });

    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [getPipelineLogs, { data: logs, isFetching, error, }] = useLazyGetPipelineLogsQuery();

    const refreshLogs = () => {
        getPipelineLogs({ input: paginationState });
    };

    useEffect(() => {
        refreshLogs();
    }, [paginationState, shouldRefresh]);

    const columns = biHubTaskRunColumnsDto(toggle);

    // These are the nested bi hub tasks
    const renderRowSubComponent =
        (row: Row<any>) => (
            <AppTable
                totalCount={0}
                showPagination={false}
                paginationState={paginationState}
                data={row.original.biHubTaskRuns}
                columns={columns}
                setPaginationState={setPaginationState}
                dark
                isNestedTable
                fullHeight
            />
        );

    let element = null;

    if (isFetching) {
        element = (
            <div className="d-flex justify-content-center">
                <div className="overlay-layer rounded bg-dark bg-opacity-5">
                    <div
                        className="spinner-border text-primary"
                        role="status"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    } else if (logs) {
        element = (
            <AppTable
                totalCount={logs.totalCount}
                paginationState={paginationState}
                data={logs.items}
                columns={biHubPipelineLogColumns}
                isLoading={isFetching}
                setPaginationState={setPaginationState}
                renderRowSubComponent={renderRowSubComponent}
            />
        );
    } else if (error) {
        RtkErrorMessage('Fetching logs failed', error);
    }

    return (
        <>
            <KTCard>
                <div className="card-header border-0 pt-6">
                    <div>
                        <button
                            type='button'
                            className="btn btn-primary my-2"
                            onClick={() => { setShouldRefresh((prevState) => !prevState); }}
                        >
                            <i className="bi bi-arrow-clockwise fs-5" />
                        </button>
                    </div>

                </div>

                <div className="card-body border-0 pt-6">
                    {element}
                </div>
            </KTCard>

            {isShowing && <AppModalWrapper title='Log' setShow={() => toggle(null)} modalProps={{ size: "lg", scrollable: false }} children={<LogViewer />} />}
        </>
    );
}

const ListWrapper: React.FunctionComponent = () => (
    <ListViewProvider>
        <List />
    </ListViewProvider>
);

export default ListWrapper;
