import { useSearchParams } from 'react-router-dom';
import { type FilterOptionDto } from '../../api/data-contracts';
import clsx from 'clsx';
import Select from 'antd/es/select';
import Dropdown from 'antd/es/dropdown';

interface Props {
  filters: FilterOptionDto[] | null | undefined;
}

function FilterButton({ filters, }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterChange = (values: Array<string | null>, filterName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    const keysToDelete: any[] = [];
    newSearchParams.forEach((_, key) => {
      if (key.startsWith(`${filterName}`)) {
        keysToDelete.push(key);
      }
    });

    keysToDelete.forEach((key) => {
      newSearchParams.delete(key as string);
    });

    values.forEach((value, index) => {
      if (value !== null) {
        newSearchParams.set(`${filterName}[${index}]`, value);
      }
    });

    setSearchParams(newSearchParams, { replace: true, });
  };

  const handleClearAllFilters = () => {
    const newSearchParams = new URLSearchParams();

    if (!filters) { return; }

    searchParams.forEach((value, key) => {
      if (!filters.some(filter => key.startsWith(filter.name))) {
        newSearchParams.set(key, value);
      }
    });

    setSearchParams(newSearchParams, { replace: true, });
  };

  if (!filters || filters?.length === 0) {
    return null;
  }

  const activeFilterCount = filters.reduce((totalCount, filter) => {
    const filterOccurrences = Array.from(searchParams.keys()).filter((key) => key.startsWith(filter.name)).length;
    return totalCount + filterOccurrences;
  }, 0);

  let items = [
    ...filters.map(filter => ({
      key: filter.name,
      label: (
        <div>
          <h4>{filter.displayName}</h4>
          <Select
            mode="multiple"
            style={{ width: 200, }}
            placeholder={`Select ${filter.displayName}`}
            onChange={(values) => { handleFilterChange(values, filter.name); }}
            value={
              Array.from(searchParams.entries())
                .filter(([key, _]) => key.startsWith(`${filter.name}[`))
                .map(([_, value]) => value)
            }
            onClick={(e) => { e.stopPropagation(); }}
          >
            {filter.options.map(option => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    }))
  ];

  if (activeFilterCount > 0) {
    items = [
      ...items,
      {
        key: 'clearAll',
        label: (
          <div
            onClick={handleClearAllFilters}
          >
            Clear All Filters
          </div>
        ),
      }
    ];
  }

  return (
    <Dropdown menu={{ items, }} >
      <button type='button' className={clsx('btn', activeFilterCount > 0 ? 'btn-primary' : 'btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary')}>
        <i className="fa-solid fa-filter"></i>
        {activeFilterCount > 0 ? `Current filters: ${activeFilterCount}` : 'Filter'}
      </button>
    </Dropdown>
  );
};

export default FilterButton;
